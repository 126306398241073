import { useState } from 'react';
import MainHeader from '../../components/MainHeader/Mainheader';
import AllPage from '../../components/AllPageConnected/AllPage';
import Footer from '../../components/Footer/Footer';
import GoogleMap from '../../components/GoogleMap/GoogleMap';
import Form from '../../components/Form/Form';
import styles from './AllModels.module.scss';

function AllModels({ setIsOpenedNav, json, setJson, lang, setLang }) {
  const [isTwoColum, setIsTwoColum] = useState(true);
  return (
    <div className={styles.container}>
      <MainHeader
        isAllPage={true}
        setIsOpenedNav={setIsOpenedNav}
        lang={lang}
        setLang={setLang}
      />
      <AllPage
        setIsTwoColum={setIsTwoColum}
        isTwoColum={isTwoColum}
        json={json}
        setJson={setJson}
        lang={lang}
        setLang={setLang}
      />
      <Form lang={lang} />
      {/* <GoogleMap /> */}
      <Footer lang={lang} />
    </div>
  );
}

export default AllModels;
