export const images = [
  {
    src: '/static/Desktop/Desktop_ru.png',
  },
];

export const mobileImages = [
  {
    src: 'static/mobile/Mobile.jpeg',
  },
];
