import styles from './Table.module.scss';
import {texts} from '../../../../Languages/texts';

function TableEngine({ text, carPackages, lang }) {
  const carTitles = carPackages?.map((car, index) => (
    <div key={car.Id} className={styles.column}>
      <div style={{backgroundColor: '#F7F7F8'}} className={styles.title}>{car.Name}</div>
      <div>{car.Engine.MaxMotorPower}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car?.Engine.FrontMotorPower}</div>
      <div>{car.Engine.MaximumSpeed}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.Engine.MaxTotalPower}</div>
      <div>{car?.Engine?.Torque}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.Engine.NumberOfElectricMotors}</div>
      <div>{car.Engine.RearMotorPower}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.Engine.EnginesType}</div>
    </div>
  ));

  return (
    <div className={styles.container}>
      <div className={styles.tab}>
        <div className={styles.column}>
          <div style={{backgroundColor: '#F7F7F8'}} className={styles.title}>{texts[lang]['engineH3']}</div>
          <div>{texts[lang]['engineItem1']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['engineItem2']}</div>
          <div>{texts[lang]['engineItem3']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['engineItem4']}</div>
          <div>{texts[lang]['engineItem5']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['engineItem6']}</div>
          <div>{texts[lang]['engineItem7']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['engineItem8']}</div>
        </div>
        {carTitles}
      </div>
      <div>{text}</div>
    </div>
  );
}

export default TableEngine;
