import React from 'react';
import styles from './Footer.module.scss'
import logo from './Img/boldLogo.png'
import {Link} from 'react-router-dom';
import {ReactComponent as WhatsApp} from './Img/whats.svg';
import {texts} from '../../Languages/texts';

function Footer({lang}) {

  return (
    <div className={styles.container}>
      <div className={styles.ftMain}>
        <Link className={styles.linkStyles} to={`/home`}>
          <img src={logo} className={styles.ftLogo} />
        </Link>
        <div className={styles.ftPages}>
          <Link className={styles.linkStyles} to={`/all`}>
            {texts[lang]['footerText']}
          </Link>
          <Link className={styles.linkStyles} to={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent('Hay Avto')}`}>
            {texts[lang]['footerAddress']}
          </Link>
        </div>
        <div className={styles.ftPages}>
          {/*<Link className={styles.linkStyles} to={`/home`}>*/}
          {/*  О нас*/}
          {/*</Link>*/}
          <a style={{cursor: "pointer"}} href="tel:+374 44-17-19-17">{texts[lang]['footerPhone']}</a>
        </div>
        <div className={styles.ftSocial}>
          <a href="https://www.instagram.com/hayavto?igsh=b2t1MWwweGtkcWlv&utm_source=qr" target="_blank"><img src="https://ncars.com.ua/catalog/view/theme/ncars/img/white_instagram.svg" /></a>
          <a target="_blank" href="https://wa.me/+37444171917"><WhatsApp style={{width: '32px', height: '32px'}} /></a>
          <a href="https://www.facebook.com/hayavto?mibextid=sCpJLy" target="_blank"><img src="https://ncars.com.ua/catalog/view/theme/ncars/img/white_facebook.svg"/></a>
        </div>
      </div>
      <div className={styles.ftBottom}>
         <p style={{color: 'white'}}>© 2010 - 2024 hayavto.am</p>
        <div className={styles.privacyPolicy}>{texts[lang]['privacyBtnText']}</div>
      </div>
    </div>
  );
}

export default Footer;
