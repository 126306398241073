import styles from './Characteristic.module.scss';
import TechCharacters from '../TechCharacters/TechCharacters';
import Packages from '../Packages/Packages';

function Characteristic({ carPackages, lang }) {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {carPackages.map((complication, index) => (
          <Packages key={index} complication={complication} lang={lang} />
        ))}
      </div>
      <TechCharacters carPackages={carPackages} lang={lang} />
    </div>
  );
}

export default Characteristic;
