import { ReactComponent as Dropdowncon } from './Img/Dropdown.svg';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ContactForm from '../Popup/ContactForm/ContactForm';
import Popup from '../Popup/Popup';
import logo from './Img/boldLogo.png';
import styles from './MainHeader.module.scss';
import { texts } from '../../Languages/texts';
import cx from 'classnames';
import FullScreenModal from '../ModalPopUp/ModalPopUp';
function MainHeader({ isAllPage, setIsOpenedNav, setLang, lang }) {
  const [isOpenContactUs, setIsOpenContactUs] = useState(false);
  const isMobile = window.innerWidth <= 768;
  const [modalOpen, setModalOpen] = useState(false);

  const togglePopup = () => {
    setIsOpenContactUs(false);
  };

  function changeLangRu() {
    setLang('RU');
  }
  function changeLangAm() {
    setLang('AM');
  }

  return (
    <>
      <div
        className={cx(styles.container, { [styles.containerColor]: isAllPage })}
      >
        <Link to={`/home`}>
          <img
            className={cx(styles.logo, { [styles.logoDark]: isAllPage })}
            src={logo}
            alt='logo'
          />
        </Link>
        {!isMobile && (
          <>
            <div className={styles.items}>{texts[lang]['headerTitle']}</div>
            <div className={styles.items} style={{ cursor: 'pointer' }}>
              +374 44-17-19-17
            </div>
            <div
              className={styles.items}
              style={{ cursor: 'pointer' }}
              onClick={() => setIsOpenContactUs(true)}
            >
              {texts[lang]['headerContact']}
            </div>
            <div
              className={styles.items}
              onClick={() => setModalOpen(true)}
              style={{ cursor: 'pointer' }}
            >
              {texts[lang]['headerOrder']}
            </div>
            <div className={styles.items}>
              <span onClick={changeLangAm}>AM</span>|
              <span onClick={changeLangRu}>RU</span>
            </div>
          </>
        )}
        <Dropdowncon
          className={styles.dropdown}
          onClick={() => setIsOpenedNav(true)}
        />
        {isOpenContactUs && (
          <Popup
            isOpenContactUs={isOpenContactUs}
            onClose={togglePopup}
            lang={lang}
          >
            <ContactForm setIsOpenContactUs={setIsOpenContactUs} lang={lang} />
          </Popup>
        )}
      </div>
      {modalOpen && (
        <FullScreenModal
          isOpen={modalOpen}
          setIsOpen={setModalOpen}
          lang={lang}
        />
      )}
    </>
  );
}

export default MainHeader;
