import styles from './AboutUs.module.scss';
import { useCallback, useMemo, useState } from 'react';
import { texts } from '../../Languages/texts';

function AboutUs({ isCarPage, res, lang }) {
  const [selectedItem, setSelectedItem] = useState(0);
  const handleClick = useCallback(
    (index) => {
      setSelectedItem(index);
    },
    [selectedItem]
  );
  const data = useMemo(() => {
    return res.map((item, index) => (
      <p
        key={index}
        className={`${styles.textItem} ${selectedItem === index ? styles.active : ''
          }`}
        onClick={() => handleClick(index)}
      >
        {item.TitleR}
      </p>
    ));
  }, [handleClick, res, selectedItem]);
  return (
    <div className={styles.container}>
      <div className={styles.aboutImg}>
        <img src={res[selectedItem]?.Image} alt='' />
      </div>
      <div className={styles.aboutText}>
        {!isCarPage && (
          <>
            <div className={styles.aboutTitle}>
              {texts[lang]['aboutUsFirstPart']}
            </div>
            <div>{texts[lang]['aboutUsSecondPart']}</div>
          </>
        )}
        {data}
        {!isCarPage && (
          <div className={styles.linkToAbout}>{texts[lang]['aboutUsBtn']}</div>
        )}
      </div>
    </div>
  );
}

export default AboutUs;
