import styles from './Home.module.scss';
import Header from '../../components/Header/Header';
import OrderInfo from '../../components/OrderInfo/OrderInfo';
import AboutUs from '../../components/AbouUs/AboutUs';
import GoogleMap from '../../components/GoogleMap/GoogleMap';
import Form from '../../components/Form/Form';
import Footer from '../../components/Footer/Footer';
import img1 from './Img/img1.jpg';
import img2 from './Img/img2.jpg';
import img3 from './Img/img3.jpg';
import img4 from './Img/img4.jpg';
import { texts } from '../../Languages/texts';
function Home({ setIsOpenedNav, setLang, lang }) {
  const items = [
    {
      TitleR: texts[lang]['banner1'],
      Image: img1,
    },
    {
      TitleR: texts[lang]['banner2'],
      Image: img2,
    },
    {
      TitleR: texts[lang]['banner3'],
      Image: img3,
    },
    {
      TitleR: texts[lang]['banner4'],
      Image: img4,
    },
  ];

  return (
    <div className={styles.container}>
      <Header setIsOpenedNav={setIsOpenedNav} setLang={setLang} lang={lang} />
      <OrderInfo lang={lang} />
      <AboutUs res={items} isCarPage={false} lang={lang} />
      <GoogleMap />
      <Form lang={lang} />
      <Footer lang={lang} />
    </div>
  );
}

export default Home;
