import React, { memo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './styles.scss';
const LoadingFallback = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <CircularProgress />
      <Typography variant="h6" className='fallbackTitle'>
        Loading, please wait...
      </Typography>
    </Box>
  );
};

export default memo(LoadingFallback);
