import styles from './ModelDescription.module.scss';
import cx from 'classnames';
import { texts } from '../../../Languages/texts';
const formatNumberWithSpaces = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const oneDollarInDram = 391;

function ModelDescription({ isTwoColum, json, lang }) {
  return (
    <div
      className={cx(styles.container, {
        [styles.containerTwoColum]: isTwoColum,
      })}
    >
      <div className={styles.titleBlock}>
        <span className={styles.title}>{json.name}</span>
        <div className={styles.descriptionContainer}>
          <span className={styles.titleDescription}>
            {texts[lang]['carComp']}
          </span>
          <span className={styles.packageCount}>{json.packagesCount}</span>
        </div>
      </div>
      <div className={styles.priceBlock}>
        <span className={styles.shareText}>{texts[lang]['shareTextFrom']}</span>
        <div className={styles.priceRow}>
          <span className={styles.dollarPrice}>
            ${formatNumberWithSpaces(json.price)}
          </span>
          <span className={styles.dramPrice}>
            {formatNumberWithSpaces(json.price * oneDollarInDram)} ֏
          </span>
        </div>
      </div>
    </div>
  );
}

export default ModelDescription;
