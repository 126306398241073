import styles from './Promo.module.scss';
import Gallery from 'react-photo-gallery';
import React, { useState, useEffect } from 'react';
import ImageFullScreenCarousel from '../../../ImageFullScreenCarousel/ImageFullScreenCarousel';

function Promo({ photos }) {
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);
  const [initialIndex, setInitialIndex] = useState(0);
  const closeModal = () => {
    setIsCarouselOpen(false);
  };
  const handleImageClick = (imageUrl) => {
    const imageIndex = photos.Images.findIndex(
      (image) => image.src === imageUrl
    );
    setInitialIndex(imageIndex);
    setIsCarouselOpen(true);
  };

  useEffect(() => {
    if (isCarouselOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isCarouselOpen]);

  return (
    <div className={styles.container}>
      {isCarouselOpen && (
        <ImageFullScreenCarousel
          images={photos?.Images}
          initialIndex={initialIndex}
          isOpen={isCarouselOpen}
          closeModal={closeModal}
        />
      )}
      <div className={styles.gallery}>
        {photos?.Images.length && (
          <Gallery
            onClick={(event) => handleImageClick(event.target.src)}
            photos={photos?.Images}
          />
        )}
      </div>
    </div>
  );
}

export default Promo;
