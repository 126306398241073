import styles from './Table.module.scss';
import {texts} from '../../../../Languages/texts';

function TableSafety({ text, carPackages, lang }) {
  const carTitles = carPackages?.map((car, index) => (
    <div key={car.Id} className={styles.column}>
      <div style={{backgroundColor: '#F7F7F8'}} className={styles.title}>{car.Name}</div>
      <div>{car.Safety.NumberOfAirbags}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car?.Safety.TPMS}</div>
      <div>{car.Safety.Abs}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.Safety.ESC}</div>
      <div>{car?.Safety?.ISOFIX}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.Safety.AutoHoldSystem}</div>
      <div>{car.Safety.LaneKeepingSystem}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.Safety.DriverFatigueMonitoringSystem}</div>
      <div>{car.Safety.TrafficSignRecognitionSystem}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.Safety.ActiveBrake}</div>
    </div>
  ));

  return (
    <div className={styles.container}>
      <div className={styles.tab}>
        <div className={styles.column}>
          <div style={{backgroundColor: '#F7F7F8'}} className={styles.title}>{texts[lang]['safetyH3']}</div>
          <div>{texts[lang]['safetyItem1']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['safetyItem2']}</div>
          <div>{texts[lang]['safetyItem3']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['safetyItem4']}</div>
          <div>{texts[lang]['safetyItem5']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['safetyItem6']}</div>
          <div>{texts[lang]['safetyItem7']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['safetyItem8']}</div>
          <div>{texts[lang]['safetyItem9']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['safetyItem10']}</div>
        </div>
        {carTitles}
      </div>
      <div>{text}</div>
    </div>
  );
}

export default TableSafety;
