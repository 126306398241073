import styles from './Packages.module.scss';
import {texts} from "../../../Languages/texts";

function Packages({complication, lang}) {

  return (
    <div className={styles.container}>
      <img src={complication?.Image} />
      <div className={styles.title}>
        <div>{texts[lang]['techH3']}</div>
        <div>{texts[lang]['sharePrice']}</div>
      </div>

      <div className={styles.description}>
       <div className={styles.name}>{complication?.Name}</div>
       <div className={styles.price}>${complication?.Price}</div>
      </div>
    </div>
  );
}

export default Packages;
