import styles from './MiddleBanner.module.scss';

function MiddleBanner({ res }) {
  return (
    <div className={styles.container}>
      <img className={styles.bannerImg} src={res?.Image} />
      <div className={styles.bannerDescription}>
        <div className={styles.bannerTitle}>{res?.TitleR}</div>
        <div className={styles.bannerText}>{res?.BodyR}</div>
      </div>
    </div>
  );
}

export default MiddleBanner;
