import { useMemo, Suspense, lazy } from 'react';
import LoadingFallback from '../LoadingFallBack/LoadingFallback';
import { useAuth } from '../../../../../Context/AuthProvider';

const CreatePage = lazy(() => import('../../Sections/Create'));
const EditPage = lazy(() => import('../../Sections/Edit'));
const DeletePage = lazy(() => import('../../Sections/Delete'));
const IsAvailablePage = lazy(() => import('../../Sections/IsAvailable'));


const CurrentPage = ({ pageName }) => {
  const { token }  = useAuth();
  const PageContent = useMemo(() => {
    switch (pageName) {
      case 'create':
        return CreatePage;
      case 'edit':
        return EditPage;
      case 'delete':
        return DeletePage; 
      case 'isAvailable':
        return IsAvailablePage;
      default:
        return CreatePage;
    }
  }, [pageName]);

  if (!token) {
    window.location.href = '/p/login'; 
    return ;  // prevent rendering the component when not authenticated.
  };
  return (
    <Suspense fallback={<LoadingFallback />}>
      {<PageContent />}
    </Suspense>
  );
};

export default CurrentPage;
