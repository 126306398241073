import styles from './Brand.module.scss';
import {ReactComponent as Zeekr} from '../BrandLogos/Zeekr.svg';
import { ReactComponent as Byd } from '../BrandLogos/Byd.svg';
import { ReactComponent as Toyota } from '../BrandLogos/Toyota.svg';
import { ReactComponent as Nissan } from '../BrandLogos/Nissan.svg';
import { ReactComponent as Audi } from '../BrandLogos/Audi.svg';
import { ReactComponent as Volkswagen } from '../BrandLogos/Volkswagen.svg';
import { ReactComponent as Honda } from '../BrandLogos/Honda.svg';
import cx from 'classnames';
import {Link} from 'react-router-dom';

function Brand({setIsOpenedNav}) {
  const brandComponents = [
    { component: Zeekr, name: 'ZEEKR' },
    { component: Byd, name: 'Byd' },
    { component: Toyota, name: 'Toyota' },
    // { component: Nissan, name: 'Nissan' },
    // { component: Audi, name: 'Audi' },
    // { component: Volkswagen, name: 'Volkswagen' },
    { component: Honda, name: 'Honda' },
  ];

  const handleClick = () => {
    setIsOpenedNav(false);
  }

  return (
    <div className={cx(styles.container, {[styles.containerPopular]: true})}>
      {brandComponents.map((brand, index) => (
        <div className={styles.brand} key={index}>
          <Link className={styles.linkStyles}
                onClick={handleClick}
                to={{
                  pathname: '/all',
                  search: `?model=${brand.name.toUpperCase()}`
                }}
          >
            <brand.component />
            <div style={{width: '100%'}}>{brand.name}</div>
          </Link>
        </div>
      ))}
    </div>
  );
}

export default Brand;
