import styles from './Table.module.scss';
import {texts} from '../../../../Languages/texts';

function TableComfort({ text, carPackages, lang }) {
  const carTitles = carPackages?.map((car, index) => (
    <div key={car.Id} className={styles.column}>
      <div style={{backgroundColor: '#F7F7F8'}} className={styles.title}>{car.Name}</div>
      <div>{car.Comfort.OnBoardComputer}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car?.Comfort.HighBeamSource}</div>
      <div>{car.Comfort.MultiSteeringWheel}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.Comfort.Parktronics}</div>
      <div>{car?.Comfort?.DaytimeRunningLights}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.Comfort.StartButton}</div>
      <div>{car.Comfort.ElectricSeats}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.Comfort.SteeringWheelTrim}</div>
      <div>{car.Comfort.Projection}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.Comfort.NumberOfSpeakers}</div>
      <div>{car.Comfort.HeatedFrontSeats}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car?.Comfort.InteriorLighting}</div>
      <div>{car.Comfort.ElectricTrunkDrive}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car?.Comfort.ClimateControl}</div>
      <div>{car.Comfort.HeatedRearSeats}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.Comfort.HeatedSteeringWheel}</div>
      <div>{car?.Comfort?.HeatedMirrors}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.Comfort.HeatedWindshield}</div>
      <div>{car.Comfort.RainSensor}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.Comfort.LightSensor}</div>
      <div>{car.Comfort.CruiseControl}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.Comfort.Camera}</div>
      <div>{car.Comfort.LowBeamSource}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car?.Comfort.ElectricMirror}</div>
      <div>{car.Comfort.Luke}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.Comfort.Autosvet}</div>
      <div>{car?.Comfort?.WirelessPhoneCharging}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.Comfort.SeatTrim}</div>
      <div>{car.Comfort.SeatMemory}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.Comfort.KeylessEntry}</div>
      <div>{car.Comfort.SeatMassage}</div>
    </div>
  ));

  return (
    <div className={styles.container}>
      <div className={styles.tab}>
        <div className={styles.column}>
          <div style={{backgroundColor: '#F7F7F8'}} className={styles.title}>{texts[lang]['comfortH3']}</div>
          <div>{texts[lang]['comfortItem1']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['comfortItem2']}</div>
          <div>{texts[lang]['comfortItem3']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['comfortItem4']}</div>
          <div>{texts[lang]['comfortItem5']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['comfortItem6']}</div>
          <div>{texts[lang]['comfortItem7']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['comfortItem8']}</div>
          <div>{texts[lang]['comfortItem9']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['comfortItem10']}</div>
          <div>{texts[lang]['comfortItem11']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['comfortItem12']}</div>
          <div>{texts[lang]['comfortItem13']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['comfortItem14']}</div>
          <div>{texts[lang]['comfortItem15']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['comfortItem16']}</div>
          <div>{texts[lang]['comfortItem17']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['comfortItem18']}</div>
          <div>{texts[lang]['comfortItem19']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['comfortItem20']}</div>
          <div>{texts[lang]['comfortItem21']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['comfortItem22']}</div>
          <div>{texts[lang]['comfortItem23']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['comfortItem24']}</div>
          <div>{texts[lang]['comfortItem25']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['comfortItem26']}</div>
          <div>{texts[lang]['comfortItem27']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['comfortItem28']}</div>
          <div>{texts[lang]['comfortItem29']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['comfortItem30']}</div>
          <div>{texts[lang]['comfortItem31']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['comfortItem32']}</div>
        </div>
        {carTitles}
      </div>
      <div>{text}</div>
    </div>
  );
}

export default TableComfort;
