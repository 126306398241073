import React, { useMemo, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import Modal from 'react-modal';
import { memo } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './ImageFullScreenCarousel.scss';

const ImageFullScreenCarousel = ({
  images,
  initialIndex,
  isOpen,
  closeModal,
}) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(initialIndex);
  const memoizedImages = useMemo(() => {
    return images.map((image, index) => (
      <div className='carousel-slide'>
        <img
          src={image.src}
          alt={`Slide ${index}`}
          className='carousel-image'
        />
      </div>
    ));
  }, [images]);

  const handlePrevClick = () => {
    if (selectedImageIndex === 0) {
      setSelectedImageIndex(images.length - 1);
      return;
    }
    setSelectedImageIndex(selectedImageIndex - 1);
  };
  const handleNextClick = () => {
    if (selectedImageIndex === images.length - 1) {
      setSelectedImageIndex(0);
      return;
    }
    setSelectedImageIndex(selectedImageIndex + 1);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel='Image Modal'
      className='modal-content'
      overlayClassName='modal-overlay'
      shouldCloseOnOverlayClick={true}
    >
      <button className='close-button' onClick={closeModal}>
        &times;
      </button>
      <div className='full-screen-carousel'>
        <div className='carousel-counter'>
          {selectedImageIndex + 1} {' / '} {images.length}
        </div>
        <Carousel
          showArrows={true}
          selectedItem={selectedImageIndex}
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          showIndicators={false}
          transitionTime={500}
          renderArrowNext={(onClickHandler, hasPrev, labelPrev) => {
            return (
              <>
                <button
                  type='button'
                  onClick={() => {
                    handlePrevClick();
                    onClickHandler();
                  }}
                  className='arrow-left'
                  onChange={(index) => setSelectedImageIndex(index)}
                />
              </>
            );
          }}
          renderArrowPrev={(onClickHandler, hasNext, labelNext) => {
            return (
              <button
                type='button'
                onClick={() => {
                  handleNextClick();
                  onClickHandler();
                }}
                className='arrow-right'
              />
            );
          }}
        >
          {memoizedImages}
        </Carousel>
      </div>
    </Modal>
  );
};

export default memo(ImageFullScreenCarousel);
