import styles from './Models.module.scss';
import { useEffect, useState } from 'react';
import cx from 'classnames';

function Models({ isTwoColum, json }) {
  const [mainPicture, setMainPicture] = useState('');
  useEffect(() => {
    setMainPicture(json?.images[0]);
  }, [json]);

  const handleHover = (pictureIndex) => {
    setMainPicture(json.images[pictureIndex]);
  };
  return (
    <div
      className={cx(styles.shopCard, { [styles.shopCardTwoColum]: isTwoColum })}
    >
      <div className={styles.pictureSection}>
        <img src={mainPicture} alt='Main' />
      </div>
      <div className={styles.sidebar}>
        {json?.images && json?.images.length > 0 && json.images.slice(0, Math.min(json.images.length, 5)).map((picture, index) => (
          <div
            key={index}
            className={styles.sidebarPart}
            onMouseEnter={() => handleHover(index)}
          ></div>
        ))}

      </div>
    </div>
  );
}

export default Models;
