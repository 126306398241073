import React, { useState } from 'react';
import styles from './Contact.module.scss';
import axios from 'axios';
import { texts } from '../../../Languages/texts';

const ContactForm = ({ setIsOpenContactUs, lang }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    PhoneNumber: '+374', // Setting the initial value with country code
  });
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => {
      const updatedData = { ...prevFormData };
      if (value) {
        updatedData[name] = value;
      } else {
        delete updatedData[name];
      }
      return updatedData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const requiredFields = ['fullName', 'PhoneNumber'];
    const missingFields = requiredFields.filter(
      (field) => !formData[field].trim()
    );

    if (missingFields.length > 0) {
      setError(true);
      return;
    }

    setError(false);

    const formDataString = Object.keys(formData)
      .map(
        (key) =>
          `${key.charAt(0).toUpperCase() + key.slice(1)}: ${formData[key]}`
      )
      .join(`<br/>`);
    // axios.get(
    //   `https://hayavto.am:8443/HayavtoAPI/Message/Send?body=${formDataString}`,
    //   {
    //     headers: {
    //       Authorization:
    //         'Basic 2f8061c0a41ee7d6e7d302d03038e6f5502c77678a67bbcb4a52f9608ec225bf',
    //     },
    //   }
    // );
    setIsOpenContactUs(false);
  };

  return (
    <form className={styles.formContainer} onSubmit={handleSubmit}>
      <div className={styles.title}>{texts[lang]['contactFormTitle']}</div>
      {error && (
        <div style={{ textAlign: 'center', color: 'red' }}>
          {texts[lang]['errorMessage']}
        </div>
      )}
      <input
        className={styles.input}
        type='text'
        name='fullName'
        value={formData.fullName}
        onChange={handleChange}
        placeholder={texts[lang]['placeholderName']}
      />
      <input
        className={styles.input}
        type='tel'
        name='PhoneNumber'
        value={formData.PhoneNumber}
        onChange={handleChange}
        maxLength={12}
        placeholder={texts[lang]['placeholderPhone']}
      />
      <button type='submit' className={styles.submit}>
        {texts[lang]['contactFormBtn']}
      </button>
    </form>
  );
};

export default ContactForm;
