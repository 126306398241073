import styles from './Brand.module.scss';

import { ReactComponent as Aito } from '../BrandLogos/Aito.svg';
import { ReactComponent as Aiways } from '../BrandLogos/Aiways.svg';
import { ReactComponent as Arcfox } from '../BrandLogos/Arcfox.svg';
import { ReactComponent as Avatr } from '../BrandLogos/Avatr.svg';
import { ReactComponent as Beijing } from '../BrandLogos/Beijing.svg';
import { ReactComponent as Bmw } from '../BrandLogos/Bmw.svg';
import { ReactComponent as Buick } from '../BrandLogos/Buick.svg';
import { ReactComponent as BYD } from '../BrandLogos/Byd.svg';
import { ReactComponent as Cadilac } from '../BrandLogos/Cadilac.svg';
import { ReactComponent as Changan } from '../BrandLogos/Chargan.svg';
import { ReactComponent as Chevrolet } from '../BrandLogos/Chevrolet.svg';
import { ReactComponent as Denza } from '../BrandLogos/Denza.svg';
import { ReactComponent as Dongfeng } from '../BrandLogos/Dongfeng.svg';
import { ReactComponent as Feifan } from '../BrandLogos/Feifan.svg';
import { ReactComponent as Forthing } from '../BrandLogos/Forthing.svg';
import { ReactComponent as Gac } from '../BrandLogos/Gac.svg';
import { ReactComponent as Gelly } from '../BrandLogos/Gelly.svg';
import { ReactComponent as Genesis } from '../BrandLogos/Genesis.svg';
import { ReactComponent as Hiphi } from '../BrandLogos/Hiphi.svg';
import { ReactComponent as Honda } from '../BrandLogos/Honda.svg';
import { ReactComponent as Hyundai } from '../BrandLogos/Hyundai.svg';
import { ReactComponent as Icar } from '../BrandLogos/Icar.svg';
import { ReactComponent as Jac } from '../BrandLogos/Jac.svg';
import { ReactComponent as Kia } from '../BrandLogos/Kia.svg';
import { ReactComponent as Leap } from '../BrandLogos/Leap.svg';
import { ReactComponent as Lexus } from '../BrandLogos/Lexus.svg';
import { ReactComponent as Livan } from '../BrandLogos/Livan.svg';
import { ReactComponent as Lotus } from '../BrandLogos/Lotus.svg';
import { ReactComponent as Luxeed } from '../BrandLogos/Luxeed.svg';
import { ReactComponent as Mazda } from '../BrandLogos/Mazda.svg';
import { ReactComponent as Mercedes } from '../BrandLogos/Mercedes.svg';
import { ReactComponent as Mitsubishi } from '../BrandLogos/Mitsubishi.svg';
import { ReactComponent as Nia } from '../BrandLogos/Nio.svg';
import { ReactComponent as Nissan } from '../BrandLogos/Nissan.svg';
import { ReactComponent as Ora } from '../BrandLogos/Ora.svg';
import { ReactComponent as Polestar } from '../BrandLogos/Polestar.svg';
import { ReactComponent as Radar } from '../BrandLogos/Radar.svg';
import { ReactComponent as Roewe } from '../BrandLogos/Roewe.svg';
import { ReactComponent as Saic } from '../BrandLogos/Saic.svg';
import { ReactComponent as Shenlan } from '../BrandLogos/Shenlan.svg';
import { ReactComponent as Smart } from '../BrandLogos/Smart.svg';
import { ReactComponent as Tesla } from '../BrandLogos/Tesla.svg';
import { ReactComponent as Toyota } from '../BrandLogos/Toyota.svg';
import { ReactComponent as Venucia } from '../BrandLogos/Venucia.svg';
import { ReactComponent as Volkswagen } from '../BrandLogos/Volkswagen.svg';
import { ReactComponent as Volvo } from '../BrandLogos/Volvo.svg';
import { ReactComponent as Voyah } from '../BrandLogos/Voyah.svg';
import { ReactComponent as Weltmeister } from '../BrandLogos/Weltmesite.svg';
import { ReactComponent as Wuling } from '../BrandLogos/Wuling.svg';
import { ReactComponent as XPeng } from '../BrandLogos/X-peng.svg';
import { ReactComponent as Yuanhang } from '../BrandLogos/Yuanhang.svg';
import { ReactComponent as Zeekr } from '../BrandLogos/Zeekr.svg';
import cx from 'classnames';
import {Link} from 'react-router-dom';


function BrandAll({setIsOpenedNav}) {
  const brandComponentsAll = [
    // { component: Aito, name: 'Aito' },
    // { component: Aiways, name: 'Aiways' },
    // { component: Arcfox, name: 'Arcfox' },
    // { component: Avatr, name: 'Avatr' },
    // { component: Beijing, name: 'Beijing' },
    { component: Bmw, name: 'Bmw' },
    // { component: Buick, name: 'Buick' },
    { component: BYD, name: 'BYD' },
    // { component: Cadilac, name: 'Cadilac' },
    { component: Changan, name: 'Changan' },
    // { component: Chevrolet, name: 'Chevrolet' },
    // { component: Denza, name: 'Denza' },
    // { component: Dongfeng, name: 'Dongfeng' },
    // { component: Feifan, name: 'Feifan' },
    // { component: Forthing, name: 'Forthing' },
    // { component: Gac, name: 'Gac' },
    // { component: Gelly, name: 'Gelly' },
    // { component: Genesis, name: 'Genesis' },
    // { component: Hiphi, name: 'Hiphi' },
    { component: Honda, name: 'Honda' },
    // { component: Hyundai, name: 'Hyundai' },
    // { component: Icar, name: 'Icar' },
    // { component: Jac, name: 'Jac' },
    // { component: Kia, name: 'Kia' },
    // { component: Leap, name: 'Leap' },
    { component: Lexus, name: 'Lexus' },
    // { component: Livan, name: 'Livan' },
    // { component: Lotus, name: 'Lotus' },
    // { component: Luxeed, name: 'Luxeed' },
    // { component: Mazda, name: 'Mazda' },
    { component: Mercedes, name: 'Mercedes-Benz' },
    // { component: Mitsubishi, name: 'Mitsubishi' },
    // { component: Nia, name: 'Nia' },
    // { component: Nissan, name: 'Nissan' },
    // { component: Ora, name: 'Ora' },
    // { component: Polestar, name: 'Polestar' },
    // { component: Radar, name: 'Radar' },
    // { component: Roewe, name: 'Roewe' },
    // { component: Saic, name: 'Saic' },
    // { component: Shenlan, name: 'Shenlan' },
    // { component: Smart, name: 'Smart' },
    // { component: Tesla, name: 'Tesla' },
    { component: Toyota, name: 'Toyota' },
    // { component: Venucia, name: 'Venucia' },
    { component: Volkswagen, name: 'Volkswagen' },
    // { component: Volvo, name: 'Volvo' },
    // { component: Voyah, name: 'Voyah' },
    // { component: Weltmeister, name: 'Weltmeister' },
    // { component: Wuling, name: 'Wuling' },
    // { component: XPeng, name: 'XPeng' },
    // { component: Yuanhang, name: 'Yuanhang' },
    { component: Zeekr, name: 'Zeekr' },
  ];

  const handleClick = () => {
    setIsOpenedNav(false);
  }

  return (
    <div className={styles.container}>
      {brandComponentsAll.map((brand, index) => (
        <div className={cx(styles.brand, {[styles.brandAll]: true})} key={index}>
          <Link className={styles.linkStyles}
                onClick={handleClick}
                to={{
                  pathname: '/all',
                  search: `?model=${brand.name.toUpperCase()}`
                }}
          >
            <brand.component />
            {brand.name}
          </Link>
        </div>
      ))}
    </div>
  );
}

export default BrandAll;
