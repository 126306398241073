// Popup.js

import React from 'react';
import styles from './Popup.module.scss';
import cx from 'classnames';
import {texts} from '../../Languages/texts';

const Popup = ({ onClose, children, isOpenContactUs, lang }) => {
  return (
    <div className={cx(styles.popup, {[styles.popupSmall]: isOpenContactUs})}>
      <button className={styles.closeButton} onClick={onClose}><img src="https://ncars.com.ua/catalog/view/theme/ncars/img/close.svg"/></button>
      {!isOpenContactUs && <div className={styles.title}>{texts[lang]['carFormTitle']}</div>}
      {children}
    </div>
  );
};

export default Popup;
