import styles from './Table.module.scss';
import {texts} from '../../../../Languages/texts';

function TableSuspensionAndWheels({ text, carPackages, lang }) {
  const carTitles = carPackages?.map((car, index) => (
    <div key={car.Id} className={styles.column}>
      <div style={{backgroundColor: '#F7F7F8'}} className={styles.title}>{car.Name}</div>
      <div>{car.SuspensionAndWheels.WheelRimMaterial}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car?.SuspensionAndWheels.FrontSuspension}</div>
      <div>{car.SuspensionAndWheels.RearSuspension}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.SuspensionAndWheels.FrontBrakes}</div>
      <div>{car?.SuspensionAndWheels?.RearBrakes}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.SuspensionAndWheels.FrontTireSize}</div>
      <div>{car.SuspensionAndWheels.RearTireSize}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.SuspensionAndWheels.ParkingBrake}</div>
      <div>{car.SuspensionAndWheels.SpareWheel}</div>
    </div>
  ));

  return (
    <div className={styles.container}>
      <div className={styles.tab}>
        <div className={styles.column}>
          <div style={{backgroundColor: '#F7F7F8'}} className={styles.title}>{texts[lang]['suspensionAndWheelsH3']}</div>
          <div>{texts[lang]['suspensionAndWheelsItem1']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['suspensionAndWheelsItem2']}</div>
          <div>{texts[lang]['suspensionAndWheelsItem3']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['suspensionAndWheelsItem4']}</div>
          <div>{texts[lang]['suspensionAndWheelsItem5']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['suspensionAndWheelsItem6']}</div>
          <div>{texts[lang]['suspensionAndWheelsItem7']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['suspensionAndWheelsItem8']}</div>
          <div>{texts[lang]['suspensionAndWheelsItem9']}</div>
        </div>
        {carTitles}
      </div>
      <div>{text}</div>
    </div>
  );
}

export default TableSuspensionAndWheels;
