import React, { useState } from 'react';
import styles from './TechCharacters.module.scss';
import Table from './Table/Table';
import {texts} from '../../../Languages/texts';
import TableGabarites from './TableGabarites/TableGabarites';
import TableEngine from './TableEngine/TableEngine';
import TableBattery from './TableBattery/TableBattery';
import TableSafety from './TableSafety/TableSafety';
import TableSuspensionAndWheels from './TableSuspensionAndWheels/TableSuspensionAndWheels';
import TableMultimedia from './TableMultimedia/TableMultimedia';
import TableComfort from './TableComfort/TableComfort';

function TechCharacters({ res, setIsOpen, carPackages, lang }) {
  const [activeTab, setActiveTab] = useState('Основные характеристики');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{texts[lang]['techH1']}</div>
      <div className={styles.tabs}>
        <div onClick={() => handleTabClick('Основные характеристики')} className={activeTab === 'Основные характеристики' ? styles.active : ''}>
          {texts[lang]['techH2']}
        </div>
        <div onClick={() => handleTabClick('Габариты')} className={activeTab === 'Габариты' ? styles.active : ''}>
          {texts[lang]['gabH3']}
        </div>
        <div onClick={() => handleTabClick('Двигатель')} className={activeTab === 'Двигатель' ? styles.active : ''}>
          {texts[lang]['engineH3']}
        </div>
        <div onClick={() => handleTabClick('Батарея')} className={activeTab === 'Батарея' ? styles.active : ''}>
          {texts[lang]['batteryH3']}
        </div>
        <div onClick={() => handleTabClick('Безопасность')} className={activeTab === 'Безопасность' ? styles.active : ''}>
          {texts[lang]['safetyH3']}
        </div>
        <div onClick={() => handleTabClick('Подвеска и колеса')} className={activeTab === 'Подвеска и колеса' ? styles.active : ''}>
          {texts[lang]['suspensionAndWheelsH3']}
        </div>
        <div onClick={() => handleTabClick('Мультимедиа')} className={activeTab === 'Мультимедиа' ? styles.active : ''}>
          {texts[lang]['multimediaH3']}
        </div>
        <div onClick={() => handleTabClick('Комфорт')} className={activeTab === 'Комфорт' ? styles.active : ''}>
          {texts[lang]['comfortH3']}
        </div>
      </div>
      {activeTab === 'Основные характеристики' &&  <Table carPackages={carPackages} lang={lang} />}
      {activeTab === 'Габариты' &&  <TableGabarites carPackages={carPackages} lang={lang} />}
      {activeTab === 'Двигатель' &&  <TableEngine carPackages={carPackages} lang={lang} />}
      {activeTab === 'Батарея' &&  <TableBattery carPackages={carPackages} lang={lang} />}
      {activeTab === 'Безопасность' &&  <TableSafety carPackages={carPackages} lang={lang} />}
      {activeTab === 'Подвеска и колеса' &&  <TableSuspensionAndWheels carPackages={carPackages} lang={lang} />}
      {activeTab === 'Мультимедиа' &&  <TableMultimedia carPackages={carPackages} lang={lang} />}
      {activeTab === 'Комфорт' &&  <TableComfort carPackages={carPackages} lang={lang} />}
    </div>
  );
}

export default TechCharacters;
