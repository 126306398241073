import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import Header from './components/Header';
import CurrentPage from './components/CurrentPage'
import checkTokenAndRedirect from './utils/checkToken'

const Admin = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get('name');

  useEffect(() => {
    // will add after backend connection
    checkTokenAndRedirect()
  }, [])

  return (
    <div>
      <Header activeTab={name} />
      <CurrentPage pageName={name} />
    </div>
  )
}

export default Admin
