import styles from './BannerFooter.module.scss';
import { texts } from '../../../Languages/texts';
import { usePrice } from '../../../Context/PreviousPageProvider';

function BannerFooter({ carPackages, lang }) {
  const { selectedPrice } = usePrice();
  return (
    <div className={styles.container}>
      <div className={styles.details}>
        <div className={styles.detailsItems}>
          <span>{carPackages[0]?.BaseDescription.NEDC}Km</span>
          <p>{texts[lang]['sliderKm']}</p>
        </div>
        <div className={styles.detailsItems}>
          <span>
            {carPackages[0]?.BaseDescription.Power?.split('/')[1]} л.с.
          </span>
          <p>{texts[lang]['sliderPower']}</p>
        </div>
        <div className={styles.detailsItems}>
          <span>{carPackages[0]?.BaseDescription.Occasion}</span>
          <p>{texts[lang]['sliderP']}</p>
        </div>
      </div>
      <div className={styles.price}>
        <span>
          {texts[lang]['shareTextFrom']} ${selectedPrice}
        </span>
        <p style={{ fontSize: '1.8vw' }}>{texts[lang]['sliderLoan']}</p>
      </div>
    </div>
  );
}

export default BannerFooter;
