import styles from './InnerBanner.module.scss';

function InnerBanner({ res }) {
  return (
    <div className={styles.container}>
      <img className={styles.innerBannerImg} src={res} />
    </div>
  );
}

export default InnerBanner;
