import styles from './Title.module.scss';
import {texts} from '../../../Languages/texts';

function Title({lang}) {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{texts[lang]['allPageTitle']}</div>
      <div className={styles.count}>{texts[lang]['allPageCount']}</div>
    </div>
  );
}

export default Title;
