import styles from './ModelButton.module.scss';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { texts } from '../../../Languages/texts';

function ModelButton({ isTwoColum, json, lang }) {
  return (
    <div
      className={cx(styles.container, {
        [styles.containerTwoColum]: isTwoColum,
      })}
    >
      <Link className={styles.linkStyles} to={`/models/${json.Id}`}>
        {texts[lang]['carLinkBtn']}
      </Link>
    </div>
  );
}

export default ModelButton;
