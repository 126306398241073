import styles from './ModelCard.module.scss';
import Models from '../Models/Models';
import ModelDescription from '../ModelDescription/ModelDescription';
import ModelButton from '../ModelButton/ModelButton';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { usePrice } from '../../../Context/PreviousPageProvider';

function ModelCard({ isTwoColum, json, selectedCar, selectedOptions, lang }) {
  const { setSelectedPrice } = usePrice();
  let cars = selectedCar
    ? json.filter((brand) => brand.model === selectedCar)
    : json;


  const isOdd = cars.length % 2 !== 0 && cars.length > 3;
  const isTwoCars = cars.length <= 2;
  const extractRangeValues = (rangeString) => {
    const [min, max] = rangeString.split('-').map(Number);
    return { min, max };
  };

  const filteredCars = cars.filter((car) => {
    return Object.entries(selectedOptions).every(([key, range]) => {
      const { min, max } = extractRangeValues(range);
      return car[key] >= min && car[key] <= max;
    });
  });

  return (
    <div
      className={cx(styles.container, {
        [styles.containerTwoColum]: isTwoColum,
        [styles.containerTwoCars]: isTwoCars || isOdd,
      })}
    >
      {cars && Boolean(cars.length) && cars?.map((item, id) => (
        < Link key={id} className={styles.unStyle} to={`/models/${item.id}`} onClick={() => setSelectedPrice(item.price)}>
          <div className={styles.carsBlock}>
            <Models isTwoColum={isTwoColum} json={item} />
            <ModelDescription isTwoColum={isTwoColum} json={item} lang={lang} />
            <ModelButton json={item} isTwoColum={isTwoColum} lang={lang} />
          </div>
        </Link>
      ))
      }
    </div >
  );
}

export default ModelCard;
