import React, { createContext, useContext, useState } from 'react';

const PriceContext = createContext();

export const PriceProvider = ({ children }) => {
  const [selectedPrice, setSelectedPrice] = useState(null);

  return (
    <PriceContext.Provider value={{ selectedPrice, setSelectedPrice }}>
      {children}
    </PriceContext.Provider>
  );
};

export const usePrice = () => useContext(PriceContext);
