import styles from './Car.module.scss';
import CarPage from '../../components/CarConnected/CarPageWithJson/CarPage';
import MainHeader from '../../components/MainHeader/Mainheader';
import BannerFooter from '../../components/CarConnected/BanerFooter/BannerFooter';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

function Car({ setIsOpenedNav, json, lang, setLang }) {
  const [response, setResponse] = useState([]);
  const [carPackages, setCarPackages] = useState([]);

  const [carBanner, setCarBanner] = useState([]);
  const [carGallery, setCarGallery] = useState([]);
  const [currentPrice, setCurrentPrice] = useState();
  const { id } = useParams();

  useEffect(() => {
    getCarById();
    getCarPackages();
    getCarBanner();
    getCarGallery();
  }, [lang]);

  const getCarById = async () => {
    try {
      const response = await axios.get(
        `https://hayavto.am:8443/HayavtoAPI/Car/GetCarById/${id}`,
        {
          headers: {
            LanguageCode: `${lang === 'AM' ? 'AM' : 'RU'}`,
            Authorization:
              'Basic 2f8061c0a41ee7d6e7d302d03038e6f5502c77678a67bbcb4a52f9608ec225bf',
          },
        }
      );
      setResponse(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const getCarPackages = async () => {
    try {
      const response = await axios.get(
        `https://hayavto.am:8443/HayavtoAPI/CarPackage/GetCarPackages?carId=${id}`,
        {
          headers: {
            LanguageCode: `${lang === 'AM' ? 'AM' : 'RU'}`,
            Authorization:
              'Basic 2f8061c0a41ee7d6e7d302d03038e6f5502c77678a67bbcb4a52f9608ec225bf',
          },
        }
      );
      setCarPackages(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getCarBanner = async () => {
    try {
      const response = await axios.get(
        `https://hayavto.am:8443/HayavtoAPI/Car/GetBannerByCarId/${id}`,
        {
          headers: {
            LanguageCode: `${lang === 'AM' ? 'AM' : 'RU'}`,
            Authorization:
              'Basic 2f8061c0a41ee7d6e7d302d03038e6f5502c77678a67bbcb4a52f9608ec225bf',
          },
        }
      );

      setCarBanner(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getCarGallery = async () => {
    try {
      const response = await axios.get(
        `https://hayavto.am:8443/HayavtoAPI/Car/GetgalleryByCarId/${id}`,
        {
          headers: {
            Authorization:
              'Basic 2f8061c0a41ee7d6e7d302d03038e6f5502c77678a67bbcb4a52f9608ec225bf',
          },
        }
      );
      const dataWithoutEmptyColorsPkgs = response.data.Exterior.filter((pkg) => pkg.Images && pkg.Images.length > 0);
      const updatedResponseData = {
        ...response.data,
        Exterior: dataWithoutEmptyColorsPkgs,
      };
      setCarGallery(updatedResponseData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className={styles.container}>
      <MainHeader
        setIsOpenedNav={setIsOpenedNav}
        response={response}
        isAllPage={false}
        setLang={setLang}
        lang={lang}
      />
      <CarPage
        response={response}
        carPackages={carPackages}
        carBanner={carBanner}
        carGallery={carGallery}
        lang={lang}
      />
      <BannerFooter currentPrice={currentPrice} carPackages={carPackages} lang={lang} />
    </div>
  );
}

export default Car;
