import React, { useState } from 'react';
import styles from './Form.module.scss';
import axios from 'axios';
import { texts } from '../../Languages/texts';

function Form({ lang }) {
  const [formData, setFormData] = useState({
    PhoneNumber: '+374',
  });
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (e.target.type === 'checkbox') {
      if (checked) {
        // If checkbox is checked, update the formData with a specific value
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: 'For contact',
        }));
      } else {
        // If checkbox is unchecked, remove the key from the formData
        const updatedFormData = { ...formData };
        delete updatedFormData[name];
        setFormData(updatedFormData);
      }
    } else {
      // For non-checkbox inputs, update the formData normally
      setFormData((prevFormData) => {
        const updatedData = { ...prevFormData };
        if (value) {
          updatedData[name] = value;
        } else {
          delete updatedData[name];
        }
        return updatedData;
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = ['FullName', 'PhoneNumber', 'Question'];
    const missingFields = requiredFields.filter(
      (field) => !formData.hasOwnProperty(field)
    );
    if (missingFields.length > 0) {
      setError(true);
      return;
    }
    setError(false);
    const phoneNumber = formData.PhoneNumber;
    delete formData.PhoneNumber;
    const formDataString = Object.keys(formData)
      .map((key) => `${key}: ${formData[key]}`)
      .join(`<br/>`);
    axios
      .get(
        `https://hayavto.am:8443/HayavtoAPI/Message/Send?body=${formDataString}<br/>PhoneNumber: ${phoneNumber}`,
        {
          headers: {
            Authorization:
              'Basic 2f8061c0a41ee7d6e7d302d03038e6f5502c77678a67bbcb4a52f9608ec225bf',
          },
        }
      )
      .catch((error) => {
        console.error('Error:', error); // Handle errors
      });
  };

  return (
    <div className={styles.formContainer}>
      <h1 className={styles.formTitle}>
        {texts[lang]['formTitleFirstPart']}{' '}
        <span style={{ color: '#aa9d5d' }}>
          {texts[lang]['formTitleSecondPart']}
        </span>
      </h1>
      {error && (
        <div style={{ textAlign: 'center', color: 'red' }}>
          {texts[lang]['errorMessage']}
        </div>
      )}
      <form className={styles.form}>
        <div className={styles.formLeft}>
          <label>{texts[lang]['formFullName']}</label>
          <input
            type='text'
            name='FullName'
            value={formData.FirstName}
            onChange={handleChange}
          />
          <label>{texts[lang]['formPhone']}</label>
          <input
            type='tel'
            name='PhoneNumber'
            value={formData.PhoneNumber}
            onChange={handleChange}
            maxLength={12}
            placeholder={texts[lang]['placeholderPhone']}
          />
        </div>
        <div className={styles.formRight}>
          <label>{texts[lang]['formQuestion']}</label>
          <textarea
            name='Question'
            value={formData.Question}
            rows={13}
            onChange={handleChange}
          />
        </div>
      </form>
      <div className={styles.formBottom}>
        <div className={styles.checkboxes}>
          <p>{texts[lang]['formContactQuestion']}</p>
          <label>
            <input type='checkbox' name='Телефоном' onChange={handleChange} />
            {texts[lang]['formContactWay1']}
          </label>
          <label>
            <input type='checkbox' name='WhatsApp' onChange={handleChange} />
            {texts[lang]['formContactWay2']}
          </label>
        </div>
        <button type='button' className={styles.submit} onClick={handleSubmit}>
          {texts[lang]['formBtnText']}
        </button>
      </div>
    </div>
  );
}

export default Form;
