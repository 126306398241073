import React, { useState, useRef } from 'react';
import styles from './Carousel.module.scss';
function Carousel({ images }) {
  const [index, setIndex] = useState(0);

  return (
    <div className={styles.carousel}>
      <img
        src={images[index].src}
        alt={`Image ${index}`}
        className={styles.img}
      />
    </div>
  );
}

export default Carousel;
