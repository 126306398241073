import React, { useMemo, Suspense, lazy } from 'react';
import LoadingFallBack from '../LoadingFallBack';
import { useAuth } from '../../../../../Context/AuthProvider';

const Banners = lazy(() => import('../UpdateSections/Banners'))
const Gallery = lazy(() => import('../UpdateSections/Gallery'))
const GeneralInformation = lazy(() => import('../UpdateSections/GeneralInformation'))
const CarPackages = lazy(() => import('../UpdateSections/CarPackages'))

const CurrentEditPage = ({ id, pageName }) => {
    const PageContent = useMemo(() => {
    switch (pageName) {
      case 'general':
        return <GeneralInformation id={id} />;
        case 'packages' : 
        return <CarPackages id={id} />;
        case 'banners' : 
        return <Banners id={id} />
        case 'gallery':
            return <Gallery id={id} />;
        default:
        return;
    }
  }, [id, pageName]);

  return (
    <>
      <Suspense fallback={<LoadingFallBack />}>
        {PageContent}
      </Suspense>
     </>
  )


}

export default CurrentEditPage;
