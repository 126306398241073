import styles from './CarPage.module.scss';
import Details from '../Details/Details';
import Form from '../../Form/Form';
import Footer from '../../Footer/Footer';
import SliderText from '../SliderText/SliderText';
import ShowMore from '../ShowMore/ShowMore';
import MiddleBanner from '../MiddleBanner/MiddleBanner';
import InnerBanner from '../InnerBanner/InnerBanner';
import AboutUs from '../../AbouUs/AboutUs';
import { useState } from 'react';
import Popup from '../../Popup/Popup';
import MyForm from '../../Popup/MyForm/MyForm';
import { texts } from '../../../Languages/texts';

function CarPage({ response, carPackages, carBanner, carGallery, lang }) {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('Основная информация');
  const [error, setError] = useState(false);
  const togglePopup = () => {
    setIsOpen(false);
  };
  return (
    <div className={styles.container}>
      <img className={styles.car} src={response.BasicInfo?.Image} />
      <SliderText res={response} carPackages={carPackages} lang={lang} />
      <Details
        res={response}
        setIsOpen={setIsOpen}
        carPackages={carPackages}
        carGallery={carGallery}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        lang={lang}
      />
      <ShowMore setActiveTab={setActiveTab} activeTab={activeTab} lang={lang} />
      <MiddleBanner res={response.Description1} />
      <MiddleBanner res={response.Description2} />
      {/*<InnerDescription res={response.twoPictureDescription} />*/}
      <InnerBanner res={response.BackgroundMiddleImgage} />
      <AboutUs res={carBanner} isCarPage={true} />
      {isOpen && (
        <Popup isOpen={isOpen} onClose={togglePopup} lang={lang}>
          {error && (
            <div style={{ textAlign: 'center', color: 'red' }}>
              {texts[lang]['errorMessage']}
            </div>
          )}
          <MyForm
            carPackages={carPackages}
            colors={response?.Colors}
            setError={setError}
            setIsOpen={setIsOpen}
            lang={lang}
          />
        </Popup>
      )}
      <Form lang={lang} />
      <Footer lang={lang} />
    </div>
  );
}

export default CarPage;
