import styles from './GoogleMap.module.scss';

function GoogleMap() {
  return (
    <div className={styles.container}>
      <gmp-map
        style={{ height: '100%' }}
        center='40.18989181518555,44.574527740478516'
        zoom='14'
        map-id='DEMO_MAP_ID'
      >
        <gmp-advanced-marker
          position='40.186750,44.569087'
          title='My location'
        ></gmp-advanced-marker>
      </gmp-map>
    </div>
  );
}

export default GoogleMap;
