import styles from './Table.module.scss';
import { texts } from '../../../../Languages/texts';

function TableGabarites({ text, carPackages, lang }) {
  const carTitles = carPackages?.map((car, index) => (
    <div key={car.Id} className={styles.column}>
      <div style={{ backgroundColor: '#F7F7F8' }} className={styles.title}>
        {car.Name}
      </div>
      <div>{car.Dimension.TrunkVolume}</div>
      <div style={{ backgroundColor: '#F7F7F8' }}>
        {car?.Dimension.BodyType}
      </div>
      <div>{car.Dimension.Length}</div>
      <div style={{ backgroundColor: '#F7F7F8' }}>{car.Dimension.Width}</div>
      <div>{car?.Dimension?.Height}</div>
      <div style={{ backgroundColor: '#F7F7F8' }}>
        {car.Dimension.CurbWeight}
      </div>
    </div>
  ));

  return (
    <div className={styles.container}>
      <div className={styles.tab}>
        <div className={styles.column}>
          <div style={{ backgroundColor: '#F7F7F8' }} className={styles.title}>
            {texts[lang]['gabH3']}
          </div>
          <div>{texts[lang]['gabaritesItem1']}</div>
          <div style={{ backgroundColor: '#F7F7F8' }}>
            {texts[lang]['gabaritesItem2']}
          </div>
          <div>{texts[lang]['gabaritesItem3']}</div>
          <div style={{ backgroundColor: '#F7F7F8' }}>
            {texts[lang]['gabaritesItem4']}
          </div>
          <div>{texts[lang]['gabaritesItem5']}</div>
          <div style={{ backgroundColor: '#F7F7F8' }}>
            {texts[lang]['gabaritesItem6']}
          </div>
        </div>
        {carTitles}
      </div>
      <div>{text}</div>
    </div>
  );
}

export default TableGabarites;
