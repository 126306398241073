import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../Header';
import CurrentEditPage from '../CurrentEditPage';
import checkTokenAndRedirect from '../../utils/checkToken';
import EditHeader from '../EditHeader';
const CarDetails = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const carId = searchParams.get('id');
  const page = searchParams.get('name');

  useEffect(() => {
    // will add after backend connection
    // checkTokenAndRedirect()
  }, [])
  return (
    <>
      <EditHeader activeTab={page} carId={carId} />
      <CurrentEditPage id={carId} pageName={page}/>
    </>
  );
};

export default CarDetails;
