import styles from './Description.module.scss';
import { texts } from '../../../Languages/texts';

function Description({ res, carPackages, handleTabClick, lang }) {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {/*<span>Электромобиль</span>*/}
        <div>{res?.TitleR}</div>
      </div>
      <div className={styles.properties}>
        <div
          className={styles.smallTitle}
        >{`${texts[lang]['carPackagesCountText1']} ${carPackages.length} ${texts[lang]['carPackagesCountText2']}`}</div>
        <div className={styles.block}>
          <div className={styles.props}>
            {carPackages.map((el, index) => (
              <div
                key={index}
                className={styles.name}
                onClick={() =>
                  handleTabClick('Комплектация и технические характеристики')
                }
              >
                {el.Name}
              </div>
            ))}
          </div>
          <div className={styles.prices}>
            {carPackages.map((el, index) => (
              <div key={index}>
                {texts[lang]['shareTextFrom']} ${el.Price}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.description}>{res?.BodyR}</div>
    </div>
  );
}

export default Description;
