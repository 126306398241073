import React, { useState } from 'react';
import styles from '../Popup.module.scss';
import axios from 'axios';
import { texts } from '../../../Languages/texts'; // Import CSS file

const MyForm = ({ carPackages, colors, setError, setIsOpen, lang }) => {
  const [formData, setFormData] = useState({
    PhoneNumber: '+374',
  });

  const packageOptions = carPackages.map((el) => (
    <option key={el.Id} value={el.Name}>
      {el.Name}
    </option>
  ));

  const colorOptions = colors?.map((el) => (
    <option key={el} value={el}>
      {el}
    </option>
  ));

  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (e.target.type === 'checkbox') {
      if (checked) {
        // If checkbox is checked, update the formData with a specific value
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: 'For contact',
        }));
      } else {
        // If checkbox is unchecked, remove the key from the formData
        const updatedFormData = { ...formData };
        delete updatedFormData[name];
        setFormData(updatedFormData);
      }
    } else {
      // For non-checkbox inputs, update the formData normally
      setFormData((prevFormData) => {
        const updatedData = { ...prevFormData };
        if (value) {
          updatedData[name] = value;
        } else {
          delete updatedData[name];
        }
        return updatedData;
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const requiredFields = [
      'fullName',
      'PhoneNumber',
      'comment',
      'country',
      'completion',
      'color',
    ]; // Replace FIELD_NAME_X with your actual field names
    const missingFields = requiredFields.filter(
      (field) => !formData.hasOwnProperty(field)
    );

    if (missingFields.length > 0) {
      // alert(`Please fill in the following required fields: ${missingFields.join(', ')}`);
      setError(true);
      return;
    }
    setError(false);

    const phoneNumber = formData.PhoneNumber;
    delete formData.PhoneNumber;

    const formDataString = Object.keys(formData)
      .map(
        (key) =>
          `${key.charAt(0).toUpperCase() + key.slice(1)}: ${formData[key]}`
      )
      .join(`<br/>`);

    axios.get(
      `https://hayavto.am:8443/HayavtoAPI/Message/Send?body=${formDataString}<br/>PhoneNumber: ${phoneNumber}`,
      {
        headers: {
          Authorization:
            'Basic 2f8061c0a41ee7d6e7d302d03038e6f5502c77678a67bbcb4a52f9608ec225bf',
        },
      }
    );
    setIsOpen(false);
  };

  return (
    <div className={styles.formContainer}>
      <form className={styles.formContainer} onSubmit={handleSubmit}>
        <div className={styles.inputsContainer}>
          <div className={styles.formField}>
            <label className={styles.label}>
              {texts[lang]['carFormFirstName']}
              <input
                className={styles.input}
                type='text'
                name='fullName'
                value={formData.fullName}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className={styles.formField}>
            <label className={styles.label}>
              {texts[lang]['carFormPhone']}
              <input
                className={styles.input}
                type='tel'
                name='PhoneNumber'
                value={formData.PhoneNumber}
                onChange={handleChange}
                maxLength={12}
                placeholder={texts[lang]['placeholderPhone']}
              />
            </label>
          </div>
          <div className={styles.formField}>
            <label className={styles.label}>
              {texts[lang]['carFormComment']}
              <textarea
                className={styles.input}
                name='comment'
                value={formData.comment}
                onChange={handleChange}
              />
            </label>
          </div>
        </div>
        <div className={styles.selectsContainer}>
          <div className={styles.formField}>
            <label className={styles.label}>
              {texts[lang]['carFormCity']}
              <select
                className={styles.input}
                name='country'
                onChange={handleChange}
              >
                <option value=''>Select Country</option>
                <option value='Armenia'>Armenia</option>
              </select>
            </label>
          </div>
          <div className={styles.formField}>
            <label className={styles.label}>
              {texts[lang]['carFormComplectaion']}
              <select
                className={styles.input}
                name='completion'
                onChange={handleChange}
              >
                <option value=''>Select Completion</option>
                {packageOptions}
              </select>
            </label>
          </div>
          <div className={styles.formField}>
            <label className={styles.label}>
              {texts[lang]['carFormColor']}
              <select
                className={styles.input}
                name='color'
                onChange={handleChange}
              >
                <option value='Color'>Select Color</option>
                {colorOptions}
              </select>
            </label>
          </div>
          <div className={styles.formBottom}>
            <div className={styles.checkboxes}>
              <p>{texts[lang]['formContactQuestion']}</p>
              <label>
                <input
                  type='checkbox'
                  onChange={handleChange}
                  name='Телефоном'
                />
                {texts[lang]['formContactWay1']}
              </label>
              <label>
                <input
                  type='checkbox'
                  onChange={handleChange}
                  name='WhatsApp'
                />
                WhatsApp
              </label>
            </div>
            <button type='submit' className={styles.submit}>
              {texts[lang]['contactFormBtn']}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MyForm;
