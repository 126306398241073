import styles from './MenuMain.module.scss';
import { ReactComponent as Zeekr } from './BrandLogos/Zeekr.svg';
import Brand from './Brand/Brand';
import BrandAll from './Brand/BrandAll';
import {texts} from '../../../Languages/texts';


function MenuMain({setIsOpenedNav, lang}) {
  const isMobile = window.innerWidth <= 768;

  return (
    <div className={styles.container}>
      <div className={styles.close} onClick={() => setIsOpenedNav(false)}><img src="https://ncars.com.ua/catalog/view/theme/ncars/img/close.svg"/></div>
      <div className={styles.title}>{texts[lang]['menuSection1']}</div>
      <Brand setIsOpenedNav={setIsOpenedNav} />
      {!isMobile &&
        <>
          <div className={styles.title}>{texts[lang]['menuSection2']}</div>
          <BrandAll setIsOpenedNav={setIsOpenedNav} />
        </>
      }
    </div>
  );
}

export default MenuMain;
