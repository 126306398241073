import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';

import Car from './Pages/Car/Car';
import Home from './Pages/Home/Home';
import AllModels from './Pages/AllModels/AllModels';
import Menu from './components/Menu/Menu';
import styles from './App.module.scss';
import Login from './Pages/DashBoard/Login';
import Admin from './Pages/DashBoard/Admin';
import CarDetails from './Pages/DashBoard/Admin/components/CarDetails';
function App() {
  const location = useLocation();
  const [lang, setLang] = useState('AM');
  const [isOpenedNav, setIsOpenedNav] = useState(false);
  const [json, setJson] = useState([]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('model')) {
      const model = queryParams.get('model');
      getAllCarsByModel(model);
    } else {
      getAllCars();
    }
  }, [location.search]);

  const getAllCars = async () => {
    try {
      const response = await axios.get(
        'https://hayavto.am:8443/HayavtoAPI/',
        {
          headers: {
            Authorization:
              'Basic 2f8061c0a41ee7d6e7d302d03038e6f5502c77678a67bbcb4a52f9608ec225bf',
          },
        }
      );

      setJson(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getAllCarsByModel = async (model) => {
    try {
      const response = await axios.get(
        `https://hayavto.am:8443/HayavtoAPI/carModels?model=${model}`,
        {
          headers: {
            Authorization:
              'Basic 2f8061c0a41ee7d6e7d302d03038e6f5502c77678a67bbcb4a52f9608ec225bf',
          },
        }
      );
      setJson(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className={styles.app}>
      <Routes>
        <Route
          index
          element={
            <Home
              setIsOpenedNav={setIsOpenedNav}
              setLang={setLang}
              lang={lang}
            />
          }
        />
        <Route
          path='/all'
          element={
            <AllModels
              setIsOpenedNav={setIsOpenedNav}
              isOpenedNav={isOpenedNav}
              json={json}
              setJson={setJson}
              lang={lang}
              setLang={setLang}
            />
          }
        />
        <Route
          path='/available'
          element={
            <AllModels
              setIsOpenedNav={setIsOpenedNav}
              isOpenedNav={isOpenedNav}
              json={json}
              setJson={setJson}
              lang={lang}
              setLang={setLang}
            />
          }
        />
        <Route
          exact
          path='/models/:id'
          element={
            <Car
              setIsOpenedNav={setIsOpenedNav}
              json={json}
              lang={lang}
              setLang={setLang}
            />
          }
        />
        <Route path='/p/login' element={<Login />} />
        <Route path='/p/admin' element={<Admin />} />
        <Route path='/p/admin/edit/page' element={<CarDetails />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
      {isOpenedNav && <Menu setIsOpenedNav={setIsOpenedNav} lang={lang} />}
    </div>
  );
}

export default App;
