import React, { memo } from 'react';
import { AppBar, Tabs, Tab } from '@mui/material';
import { adminEditPages } from '../../../../../constants';
import { useNavigate } from 'react-router-dom';

const EditHeaderWithTabs = ({ activeTab, carId }) => {
  const navigate = useNavigate()

  const handleChange = (_, newValue) => {
    const tabName = adminEditPages[newValue].name;
    navigate(`/p/admin/edit/page?name=${tabName}&carModel&id=${carId}`)
  };

  return (
    <AppBar position="static" sx={{ justifyContent: 'center' }}>
      <Tabs
        value={adminEditPages.findIndex((tab) => tab.name === activeTab)}
        onChange={handleChange}
        aria-label="admin tabs"
        textColor="inherit"
        indicatorColor="secondary"
      >
        {adminEditPages.map((item, index) => (
          <Tab key={index} label={item.title} />
        ))}
      </Tabs>
    </AppBar>
  );
};

export default memo(EditHeaderWithTabs);
