import styles from './OrderInfo.module.scss';
import UserCard from './UserCard';
import {texts} from '../../Languages/texts';

function OrderInfo({ lang }) {
    const description1 = {
        text1 :texts[lang]['orderInfoText1'],
        text2 :texts[lang]['orderInfoText2'],
        text3 :texts[lang]['orderInfoText3'],
        text4 :texts[lang]['orderInfoText4'],
    }

    const description2 = {
        text1 :texts[lang]['orderInfoText21'],
        text2 :texts[lang]['orderInfoText22'],
        text3 :texts[lang]['orderInfoText23'],
        text4 :texts[lang]['orderInfoText24'],
    }

    return (
    <div className={styles.container}>
      <div>
        <h1 style={{textAlign: "left"}}>{texts[lang]['orderInfoLeft']}</h1>
        <UserCard
          description={description1}
          picture="https://ncars.com.ua/catalog/view/theme/ncars/img/main_img1.jpg"
          lang={lang}
          isAvailable={false}
        />
      </div>
      <div>
        <h1 style={{textAlign: "left"}}>{texts[lang]['orderInfoRight']}</h1>
        <UserCard
          description={description2}
          picture="https://ncars.com.ua/catalog/view/theme/ncars/img/main_img2.jpg"
          lang={lang}
          isAvailable={true}
        />
      </div>
    </div>
  );
}

export default OrderInfo;
