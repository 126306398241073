import React, { useCallback, useMemo, useRef } from 'react';
import Modal from 'react-modal';
import styles from './ModalPopUp.module.scss';
import cx from 'classnames';
import { texts } from '../../Languages/texts';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import ReactCountryFlag from 'react-country-flag';
import { useState } from 'react';
import { SocialIcon } from 'react-social-icons';
import Checkbox from '@mui/material/Checkbox';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { ReactComponent as ViberSvg } from '../MainHeader/Img/viber-svgrepo-com.svg';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
Modal.setAppElement('#root');

const FullScreenModal = ({ isOpen, setIsOpen, lang }) => {
  const handleClose = () => {
    setIsOpen(false);
  };
  const phoneInputRef = useRef(null);

  const [phoneInputColor, setPhoneInputColor] = useState('primary');
  const [checked, setChecked] = useState({
    viber: false,
    whatsapp: false,
    telegram: false,
  });

  const [inputValues, setInputValues] = useState({
    name: '',
    phone: '',
    marc: '',
    year: '',
    list: '',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const [selectedCountry, setSelectedCountry] = useState('AM');
  const [phoneInputFocus, setPhoneInputFocus] = useState(false);
  const sngCountryCodes = ['AM', 'BY', 'KG', 'KZ', 'MD', 'RU', 'UA'];
  const phoneCodes = {
    AM: '+374',
    BY: '+375',
    KG: '+996',
    KZ: '+7',
    MD: '+373',
    RU: '+7',
    UA: '+380',
  };

  const handleCheckboxChange = useCallback((e, name) => {
    setChecked((prev) => ({ ...prev, [name]: !prev[name] }));
  }, []);

  const handleOnPressKey = useCallback((event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  }, []);

  const memoCountries = useMemo(() => {
    return sngCountryCodes.map((countryCode) => (
      <MenuItem key={countryCode} value={countryCode}>
        <ReactCountryFlag
          countryCode={countryCode}
          svg
          className={styles.flag}
        />
        <span className={styles.flagText}>{`${phoneCodes[countryCode]}`}</span>{' '}
      </MenuItem>
    ));
  }, []);

  const handleInputChange = (e, field) => {
    if (phoneInputColor === 'error') {
      {
        setPhoneInputColor('primary');
      }

      setInputValues({ ...inputValues, [field]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedCheckboxes = Object.keys(checked).filter(
      (key) => checked[key]
    );
    if (!inputValues.phone) {
      setPhoneInputColor('error');
      setPhoneInputFocus(true);
      phoneInputRef.current.focus();
      return;
    }

    const phoneNumber = phoneCodes[selectedCountry] + ' ' + inputValues.phone;
    const formData = {
       անունը: inputValues.name,
      'Մեքենայի ապրանքանիշ և մոդել': inputValues.marc,
      'Թողարկման տարեթիվը': inputValues.year,
      'Պահեստամասերի ցուցակ': inputValues.list,
      'Մեսսենջեր հաղորդակցության համար': selectedCheckboxes,
    };

    const formDataString = Object.keys(formData)
      .map((key) => `${key}: ${formData[key]}`)
      .join(`<br/>`);

    try {
      axios.get(
        `https://hayavto.am:8443/HayavtoAPI/Message/Send?body=${formDataString}<br/>PhoneNumber: ${phoneNumber}`,
        {
          headers: {
            Authorization:
              'Basic 2f8061c0a41ee7d6e7d302d03038e6f5502c77678a67bbcb4a52f9608ec225bf',
          },
        }
      );

      setSnackbarMessage(texts[lang]['submitSuccessMessage']);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setInputValues({
        name: '',
        phone: '',
        marc: '',
        year: '',
        list: '',
      });
      setTimeout(() => {
        handleClose();
      }, 3000);
    } catch (error) {
      setSnackbarMessage(texts[lang]['submitErrorMessage']);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Error:', error);
    }
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={handleClose}
        contentLabel='Image Modal'
        className='modal-content'
        overlayClassName={styles.modalOverlay}
        shouldCloseOnOverlayClick={true}
      >
        <div className={cx(styles.popup, { [styles.popupSmall]: true })}>
          <button className={styles.closeButton} onClick={handleClose}>
            &times;
          </button>
          <div className={styles.titlesWrapper}>
            <Box className={styles.boxContainer}>
              <Typography
                fontWeight='bold'
                fontSize={25}
                mt={2}
                variant='h5'
                gutterBottom
                flexWrap='nowrap'
              >
                {texts[lang]['orderpopupTitle']}
              </Typography>
              <Typography variant='subtitle1' gutterBottom>
                {texts[lang]['orderpopupInfo']}
              </Typography>
            </Box>

            <FormControl
              className={styles.formControlContainer}
              sx={{ m: 3 }}
              variant='standard'
            >
              <TextField
                id='outlined-basic'
                label={texts[lang]['orderpopUpName']}
                variant='outlined'
                color='primary'
                onChange={(e) => handleInputChange(e, 'name')}
              />
              <div className={styles.selectWrapper}>
                <Select
                  label={texts[lang]['orderpopUpCountry']}
                  value={selectedCountry}
                  id='outlined-basic'
                  variant='outlined'
                  className={styles.select}
                  onChange={(e) => {
                    setSelectedCountry(e.target.value);
                  }}
                >
                  {memoCountries}
                </Select>
                <TextField
                  inputRef={phoneInputRef}
                  color={phoneInputColor}
                  label={texts[lang]['orderpopUpPhone']}
                  variant='outlined'
                  fullWidth
                  autoFocus={phoneInputFocus}
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    onKeyPress: (event) => handleOnPressKey(event),
                  }}
                  onChange={(e) => handleInputChange(e, 'phone')}
                />
              </div>
              <TextField
                id='outlined-basic'
                label={texts[lang]['orderpopupMarc']}
                variant='outlined'
                onChange={(e) => handleInputChange(e, 'marc')}
              />
              <TextField
                id='outlined-basic'
                label={texts[lang]['orderpopupYear']}
                variant='outlined'
                onChange={(e) => handleInputChange(e, 'year')}
              />
              <TextareaAutosize
                placeholder={texts[lang]['orderpopUpList']}
                minRows={2}
                className={styles.textArea}
                onChange={(e) => handleInputChange(e, 'list')}
              />
              <Typography variant='subtitle1' gutterBottom>
                {texts[lang]['orderpopupMessanger']}
              </Typography>
              <div className={styles.checkboxWrapper}>
                <div
                  className={cx(styles.checkboxContainer, {
                    [styles.checkboxContainerChecked]: checked.viber,
                  })}
                >
                  <Checkbox
                    icon={
                      <ViberSvg style={{ width: '55px', height: '55px' }} />
                    }
                    checkedIcon={
                      <ViberSvg style={{ width: '55px', height: '55px' }} />
                    }
                    checked={checked.viber}
                    onChange={(e) => {
                      handleCheckboxChange(e, 'viber');
                    }}
                    inputProps={{ 'aria-label': 'viber' }}
                  />
                </div>
                <div
                  className={cx(styles.checkboxContainer, {
                    [styles.checkboxContainerChecked]: checked.whatsapp,
                  })}
                >
                  <Checkbox
                    icon={
                      <SocialIcon
                        url='https://whatsapp.com'
                        onClick={(e) => e.stopPropagation()}
                      />
                    }
                    checkedIcon={<SocialIcon url='https://whatsapp.com' />}
                    checked={checked.whatsapp}
                    onChange={(e) => handleCheckboxChange(e, 'whatsapp')}
                    inputProps={{ 'aria-label': 'WhatsApp' }}
                  />
                </div>
                <div
                  className={cx(styles.checkboxContainer, {
                    [styles.checkboxContainerChecked]: checked.telegram,
                  })}
                >
                  <Checkbox
                    icon={<SocialIcon url='https://telegram.com' />}
                    checkedIcon={<SocialIcon url='https://telegram.com' />}
                    checked={checked.telegram}
                    onChange={(e) => handleCheckboxChange(e, 'telegram')}
                    inputProps={{ 'aria-label': 'telegram' }}
                  />
                </div>
              </div>
              <div className={styles.buttonWrapper}>
                <button
                  type='button'
                  className={styles.submit}
                  onClick={(e) => handleSubmit(e)}
                >
                  {texts[lang]['orderpopsubmit']}
                </button>
              </div>
            </FormControl>
          </div>
        </div>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default FullScreenModal;
