import React, { useState } from 'react';
import styles from './Details.module.scss';
import Description from '../Description/Description';
import Gallery from '../Gallery/Gallery';
import Characteristic from '../Characteristics/Characteristic';
import { texts } from '../../../Languages/texts';

function Details({
  res,
  setIsOpen,
  carPackages,
  carGallery,
  activeTab,
  setActiveTab,
  lang,
}) {
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const redirectToBank = () => {
    window.open('https://www.byblosbankarmenia.am/hy/loan/auto-loan/al');
  };

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <div
          onClick={() => handleTabClick('Основная информация')}
          className={
            activeTab === 'Основная информация' ? styles.active : styles.tab
          }
        >
          {texts[lang]['carPageNavTab1']}
        </div>
        <div
          onClick={() =>
            handleTabClick('Комплектация и технические характеристики')
          }
          className={
            activeTab === 'Комплектация и технические характеристики'
              ? styles.active
              : styles.tab
          }
        >
          {texts[lang]['carPageNavTab2']}
        </div>
        <div
          onClick={() => handleTabClick('Галерея')}
          className={activeTab === 'Галерея' ? styles.active : styles.tab}
        >
          {texts[lang]['carPageNavTab3']}
        </div>
        <button className={styles.btn} onClick={redirectToBank}>
          {texts[lang]['carPageBankCalculator']}
        </button>
        <button className={styles.btn} onClick={() => setIsOpen(true)}>
          {texts[lang]['carPageBuyBtn']}
        </button>
      </div>
      {activeTab === 'Основная информация' && (
        <Description
          res={res.BasicInfo}
          carPackages={carPackages}
          handleTabClick={handleTabClick}
          lang={lang}
        />
      )}
      {activeTab === 'Комплектация и технические характеристики' && (
        <Characteristic carPackages={carPackages} lang={lang} />
      )}
      {activeTab === 'Галерея' && <Gallery res={carGallery} lang={lang} />}
    </div>
  );
}

export default Details;
