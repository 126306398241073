import React from 'react';
import { AppBar, Tabs, Tab } from '@mui/material';
import { adminPages } from '../../../../../constants';
import { useNavigate } from 'react-router-dom';

const HeaderWithTabs = ({ activeTab }) => {
  const navigate = useNavigate()

  const handleChange = (_, newValue) => {
    const tabName = adminPages[newValue].name;
    navigate(`/p/admin?name=${tabName}`);
  };

  return (
    <AppBar position="static" sx={{ justifyContent: 'center' }}>
      <Tabs
        value={adminPages.findIndex((tab) => tab.name === activeTab)}
        onChange={handleChange}
        aria-label="admin tabs"
        textColor="inherit"
        indicatorColor="secondary"
      >
        {adminPages.map((item, index) => (
          <Tab key={index} label={item.title} />
        ))}
      </Tabs>
    </AppBar>
  );
};

export default HeaderWithTabs;
