const checkTokenAndRedirect = () => {
  const token = localStorage.getItem('token');

  // Check if token exists
  if (!token) {
    // Redirect to login page
    window.location.href = '/p/login';
  }
};

export default checkTokenAndRedirect;
