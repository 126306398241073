export const texts = {
  AM: {
    banner1: 'ՄԱՍՆԱՃՅՈՒՂ ՀԱՅԱՍՏԱՆՈՒՄ',
    banner2: 'ՉԻՆԱՍՏԱՆԻՑ ՆԵՐՄՈՒԾՄԱՆ 1 ՏԱՐՎԱ ՓՈՐՁ',
    banner3: 'ՕՖԼԱՅՆ ՀԱՄԱԳՈՐԾԱԿՑՈՒԹՅՈՒՆ ՉԻՆԱՍՏԱՆԻ ԱՎՏՈՊՐՈՄԻ ՀԵՏ',
    banner4: 'ՓՈՐՁՎԱԾ ԼՈԳԻՍՏԻԿ ԵՐԹՈՒՂԻՆԵՐ',
    headerTitle: 'Նոր մեքենաներ Չինաստանից',
    headerContact: 'Կապվեք մեզ հետ',
    orderInfoTitle: 'ԱՌԱՆՁՆԱՀԱՏԿՈՒԹՅՈՒՆՆԵՐ',
    orderInfoLeft: 'ՊԱՏՎԵՐՈՎ',
    orderInfoRight: 'ԱՌԿԱ',
    orderInfoText1: '+ Ամենատարածված տարբերակը մեր հաճախորդների շրջանում',
    orderInfoText2:
      '+ Մոդելը, կոմպլեկտացիան, գույնը ինքնուրույն ընտրելու հնարավորություն',
    orderInfoText3: '+ Ավելի շահավետ գին',
    orderInfoText4: '- Երկար սպասման ժամկետ',
    orderInfoBtnText: 'Տեսնել տեսականին',
    orderInfoText21:
      '+ Էլեկտրական մեքենան սեփական աչքերով տեսնելու հնարավորություն',
    orderInfoText22: '+ Չի պահանջվում սպասել առաքմանը',
    orderInfoText23: '- Կոնֆիգուրացիան և գույնը փոխելու հնարավորություն չկա',
    orderInfoText24: '- Գինը ավելի բարձր է, քան պատվիրելու դեպքում',
    aboutUsBtn: 'Ավելին մեր մասին',
    aboutUsFirstPart: 'Մի քիչ',
    aboutUsSecondPart: 'ՀայԱվտո-ի Մասին',
    formTitleFirstPart: 'ՀԱՐՑԵՐ ՈՒՆԵ՞Ք։',
    formTitleSecondPart: 'ՀԱՐՑՐԵՔ ՄԵԶ!',
    formBtnText: 'ՈՒՂԱՐԿԵԼ ՀԱՅՏ',
    formFullName: 'Ձեր անուն ազգանունը',
    formPhone: 'Ձեր հեռախոսահամարը',
    formQuestion: 'Ձեր հարցը',
    formContactQuestion: 'Ո՞րն է ձեզ հետ կապվելու ամենահարմար տարբերակը:',
    formContactWay1: 'Հեռախոսահամարով',
    formContactWay2: 'WhatsApp',
    footerText: 'Արդեն Հայաստանում',
    footerAddress: 'Փողոց Թևոսյան 21/5, Երևան Հայաստան',
    footerPhone: 'Հայաստան :+374 44-17-19-17',
    privacyBtnText: 'Գաղտնիության քաղաքականություն',
    menuItem1: 'Մոդելների տեսակները',
    menuItem2: 'Առկա տեսականին',
    menuItem3: 'Մեր մասին',
    menuSection1: 'Հայտնի մոդելներ',
    menuSection2: 'Բոլոր մոդելները',
    contactFormTitle: 'Կապվեք մեզ հետ',
    contactFormBtn: 'Ուղարկել հայտ',
    allPageTitle: 'Էլեկտրոմեքենաներ',
    allPageCount: 'Նախնական պատվերի 47',
    carPageBuyBtn: 'Պատվիրել',
    carPageBankCalculator: 'Վարկով',
    carPackagesCountText1: 'Նախապես պատվիրելու համար',
    carPackagesCountText2: 'կոմպլեկտացիա',
    showMore: 'Ցույց տալ ավելին',
    carPageNavTab1: 'Հիմնական ինֆորմացիա',
    carPageNavTab2: 'Կոմպլեկտացիաներ ու տեխնիկական հատկանիշներ',
    carPageNavTab3: 'Նկարներ',
    techH1: 'ՏԵԽՆԻԿԱԿԱՆ ՀԱՏԿԱՆԻՇՆԵՐ',
    techH2: 'ՀԻՄՆԱԿԱՆ ՀԱՏԿԱՆԻՇՆԵՐ',
    techH3: 'Կոմպլեկտացիա',
    techItems1: 'Մարտկոցի ծավալը, կՎտ',
    techItems2: 'Կռուիզինգի միջակայք (NEDC), կմ',
    techItems3: 'Արագացում 0-100 կմ',
    techItems4: 'Նստատեղերի քանակը',
    techItems5: 'Հզորությունը, կՎտ/ձիաուժ',
    techItems6: 'Առավելագույն արագություն, կմ/ժ',
    techItems7: 'Քարշակ',
    techItems8: 'Լիցքավորման արագություն',
    carGalleryTab1: 'ԱՐՏԱՔԻՆ',
    carGalleryTab2: 'ԻՆՏԵՐԻԵՐ',
    carGalleryTab3: 'ՊՐՈՄՈ',
    carLinkBtn: 'Ավելին',
    carComp: 'Կոմպլեկտացիաներ',
    carColor: 'Գույներ',
    selectModel: 'Ընտրել մոդելը',
    selectKm: 'Էլ․ պահուստ',
    selectBattery: 'Մարտկոցի հզորությունը',
    selectPrice: 'Գին, $',
    sliderText: 'ԱՌԿԱ և ՊԱՏՎԵՐՈՎ',
    sliderKm: 'ՊԱՀՈՒՍՏ',
    sliderPower: 'ՀԶՈՐՈՒԹՅՈՒՆ',
    sliderP: 'ՔԱՐՇԱԿ',
    sliderLoan: 'ապառիկ սկսած 0,01%',
    shareTextFrom: 'սկսած',
    sharePrice: 'Գինը սկսած',
    carFormTitle: 'Նախնական պատվերի հայտ',
    carFormFirstName: 'Ձեր ամբողջական անունը',
    carFormPhone: 'Ձեր հեռախոսահամարը',
    carFormComment: 'Ձեր մեկնաբանությունը',
    carFormCity: 'Ընտրեք քաղաքը',
    carFormComplectaion: 'Ընտրեք կոմպլեկտացիան',
    carFormColor: 'Ընտրեք գույնը',
    errorMessage: 'Խնդրում ենք լրացրեք բոլոր դաշտերը',
    gabaritesItem1: 'Բեռնախցիկի ծավալը (լ)',
    gabaritesItem2: 'Թափքի տեսակը',
    gabaritesItem3: 'Երկարություն (մմ)',
    gabaritesItem4: 'Լայնություն (մմ)',
    gabaritesItem5: 'Բարձրություն (մմ)',
    gabaritesItem6: 'Քաշը (կգ)',
    gabH3: 'ՉԱՓԵՐԸ',
    engineH3: 'ՇԱՐԺԻՉ',
    engineItem1: 'Շարժիչի առավելագույն հզորությունը (ձիաուժ)',
    engineItem2: 'Առջևի շարժիչի հզորությունը (կՎտ)',
    engineItem3: 'Առավելագույն արագություն (կմ/ժ)',
    engineItem4: 'Ընդհանուր առավելագույն հզորությունը (կՎտ)',
    engineItem5: 'Ոլորող պահ (Նմ)',
    engineItem6: 'Էլեկտրական շարժիչների քանակը',
    engineItem7: 'Հետևի շարժիչի հզորությունը (կՎտ)',
    engineItem8: 'Շարժիչի տեսակը',
    batteryH3: 'ՄԱՐՏԿՈՑ',
    batteryItem1: 'Մարտկոցի հզորությունը (կՎտժ)',
    batteryItem2: 'Արագ լիցքավորում (ժամեր)',
    batteryItem3: 'Դանդաղ լիցքավորում (ժամ)',
    batteryItem4: 'Մարտկոցի սառեցում',
    batteryItem5: 'Մարտկոցի տեսակ',
    batteryItem6: 'Մարտկոցի նախնական տաքացում',
    safetyItem1: 'Անվտանգության բարձիկների քանակը',
    safetyItem2: 'Անվադողերի ճնշման մոնիտորինգի համակարգ (TPMS)',
    safetyItem3: 'ABS',
    safetyItem4: 'ESP',
    safetyItem5: 'ԻՍՕՖԻՀ',
    safetyItem6: 'Ավտոմատ պահման համակարգ',
    safetyItem7: 'Lane Keeping համակարգ',
    safetyItem8: 'Վարորդի հոգնածության մոնիտորինգի համակարգ',
    safetyItem9: 'Ճանապարհային նշանների ճանաչման համակարգ',
    safetyItem10: 'Ակտիվ արգելակ',
    safetyH3: 'ԱՆՎՏԱՆԳՈՒԹՅՈՒՆ',
    suspensionAndWheelsItem1: 'Անիվի եզրերի նյութը',
    suspensionAndWheelsItem2: 'Առջևի կախոց',
    suspensionAndWheelsItem3: 'Հետևի կախոց',
    suspensionAndWheelsItem4: 'Առջևի արգելակներ',
    suspensionAndWheelsItem5: 'Հետևի արգելակներ',
    suspensionAndWheelsItem6: 'Առջևի անվադողի չափը',
    suspensionAndWheelsItem7: 'Հետևի անվադողի չափը',
    suspensionAndWheelsItem8: 'Կայանման արգելակ',
    suspensionAndWheelsItem9: 'Պահեստային անիվ',
    suspensionAndWheelsH3: 'ԿԱԽՈՑ ԵՎ ԱՆԻՎՆԵՐ',
    multimediaItem1: 'CarPlay աջակցություն',
    multimediaItem2: 'Վահանակ',
    multimediaItem3: 'Android Auto-ի աջակցություն',
    multimediaItem4: 'Կենտրոնական էկրան',
    multimediaItem5: 'Կենտրոնական էկրանի չափը (դյույմ)',
    multimediaItem6: 'GPS նավիգացիոն համակարգ',
    multimediaItem7: 'Բարձրախոս (Hands Free)',
    multimediaItem8: 'Bluetooth',
    multimediaItem9: 'Անգլերեն Լեզու',
    multimediaH3: 'ՄՈՒԼՏԻՄԵԴԻԱ',
    comfortH3: 'ՀԱՐՄԱՐԱՎԵՏՈՒԹՅՈՒՆ',
    comfortItem1: 'Բորտ համակարգիչ',
    comfortItem2: 'Բարձր լույսի աղբյուր',
    comfortItem3: 'Բազմաֆունկցիոնալ ղեկ',
    comfortItem4: 'Parktronics',
    comfortItem5: 'Ցերեկային վազող լույսեր',
    comfortItem6: 'Թողարկում կոճակ',
    comfortItem7: 'Էլեկտրական նստատեղեր',
    comfortItem8: 'Ղեկի զարդարանք',
    comfortItem9: 'Պրոյեկցիա',
    comfortItem10: 'Բարձրախոսների թիվը',
    comfortItem11: 'Առջևի նստատեղերի ջեռուցում',
    comfortItem12: 'Ներքին լուսավորություն',
    comfortItem13: 'Էլեկտրական բեռնախցիկ',
    comfortItem14: 'Կլիմայի վերահսկում',
    comfortItem15: 'Հետևի նստատեղերի ջեռուցում',
    comfortItem16: 'Տաքացվող ղեկ',
    comfortItem17: 'Տաքացվող հայելիներ',
    comfortItem18: 'Տաքացվող դիմապակին',
    comfortItem19: 'Անձրևի սենսոր',
    comfortItem20: 'Լույսի սենսոր',
    comfortItem21: 'Կրուիզ կոնտրոլ',
    comfortItem22: 'Տեսախցիկ',
    comfortItem23: 'Ցածր լույսի աղբյուր',
    comfortItem24: 'Էլեկտրական հայելիներ',
    comfortItem25: 'Երդիկ',
    comfortItem26: 'Ավտոլույս',
    comfortItem27: 'Հեռախոսի անլար լիցքավորում',
    comfortItem28: 'Նստատեղերի հարդարում',
    comfortItem29: 'Նստատեղերի հիշողություն',
    comfortItem30: 'Առանց բանալի մուտք',
    comfortItem31: 'Նստատեղերի մերսում',
    headerOrder: 'ՊԱՏՎԻՐԵԼ',
    placeholderName: 'Ձեր անունը',
    placeholderPhone: 'Ձեր հեռախոսահամարը (ներառյալ +374)',
    orderpopupTitle: 'ՊԱՏՎԻՐԵԼ ԱՎՏՈՊԱՀԵՍՏԱՄԱՍԵՐ',
    orderpopupInfo: 'Մենք ձեզ կպատասխանենք 12 ժամվա ընթացքում',
    orderpopUpName: 'Ձեր անունը',
    orderpopUpPhone: 'Ձեր հեռախոսահամարը',
    orderpopupMessanger: 'Մեսսենջեր հաղորդակցության համար',
    orderpopupMarc: 'Էլեկտրական մեքենայի ապրանքանիշ և մոդել',
    orderpopupYear: 'Թողարկման տարեթիվը',
    orderpopUpList: 'Պահեստամասերի ցուցակ',
    orderpopsubmit: 'Ուղարկել հայտ',
    submitSuccessMessage: 'Ձեր հաղորդագրությունը հաջողությամբ ուղարկվել է',
    submitErrorMessage:
      'Ֆայլը ուղարկել չհաջողվեց: Խնդրում ենք փորձել կրկին ավելի ուշ:',
  },
  RU: {
    banner1: 'ФИЛИАЛ В Армении',
    banner2: '1 ЛЕТ ОПЫТА ИМПОРТА ИЗ КИТАЯ',
    banner3: 'ОФЛАЙН СОТРУДНИЧЕСТВО С АВТОПРОМОМ КИТАЯ',
    banner4: 'ПРОВЕРЕННЫЕ ЛОГИСТИЧЕСКИЕ МАРШРУТЫ',
    headerTitle: 'Новые Электромобили из Китая',
    headerContact: 'Связаться с нами',
    orderInfoTitle: 'ОСОБЕННОСТИ',
    orderInfoLeft: 'ПОД ЗАКАЗ',
    orderInfoRight: 'НАЛИЧИЕ',
    orderInfoText1: '+ Самый распространенный вариант среди наших клиентов',
    orderInfoText2:
      '+ Возможность самостоятельно выбрать модель, комплектацию, цвет',
    orderInfoText3: '+ Более выгодная цена',
    orderInfoText4: '- Долгое время ожидания',
    orderInfoText21: '+ Возможность увидеть электромобиль своими глазами',
    orderInfoText22: '+ Не требует ожидания доставки',
    orderInfoText23: '– Нет возможности изменения комплектации и цвета',
    orderInfoText24: '– Цена выше чем под заказ\n',
    orderInfoBtnText: 'Смотреть каталог',
    aboutUsBtn: 'Подробнее о нас',
    aboutUsFirstPart: 'НЕМНОГО О',
    aboutUsSecondPart: 'HAYAVTO',
    formTitleFirstPart: 'У ВАС ЕСТЬ ВОПРОСЫ?',
    formTitleSecondPart: 'ЗАДАЙТЕ ЕГО НАМ!',
    formBtnText: 'Отправить заявку',
    formFullName: 'Ваш ФИО',
    formPhone: 'Номер телефона',
    formQuestion: 'Ваш вопрос',
    formContactQuestion: 'Как с Вами удобнее связаться?',
    formContactWay1: 'Телефон',
    formContactWay2: 'WhatsApp',
    footerText: 'УЖЕ В АРМЕНИИ',
    footerAddress: 'УЛИЦА ТЕВОСЯН 21/5, YEREVAN ARMENIA',
    footerPhone: 'Армения :+374 44-17-19-17',
    privacyBtnText: 'Политика конфиденциальности',
    menuItem1: 'Модельный ряд',
    menuItem2: 'В наличии',
    menuItem3: 'О нас',
    menuSection1: 'Популярные марки',
    menuSection2: 'Все марки',
    contactFormTitle: 'Связаться с нами',
    contactFormBtn: 'ОТПРАВИТЬ ЗАЯВКУ',
    allPageTitle: 'Электромобили',
    allPageCount: 'Предзаказ 47',
    carPageBuyBtn: 'ЗАКАЗАТЬ',
    carPageBankCalculator: 'В КРЕДИТ',
    carPackagesCountText1: 'Для предзаказа',
    carPackagesCountText2: 'комплектации',
    showMore: 'Показать больше фото',
    carPageNavTab1: 'Основная информация',
    carPageNavTab2: 'Комплектация и технические характеристики',
    carPageNavTab3: 'Галерея',
    techH1: 'ТЕХНИЧЕСКИЕ ХАРАКТЕРИСТИКИ',
    techH2: 'ОСНОВНЫЕ ХАРАКТЕРИСТИКИ',
    techH3: 'КОМПЛЕКТАЦИЯ',
    techItems1: 'ЕМКОСТЬ БАТАРЕИ, КВТ',
    techItems2: 'ЗАПАС ХОДА (NEDC), КМ',
    techItems3: 'РАЗГОН 0-100 КМ, СЕК',
    techItems4: 'КОЛИЧЕСТВО МЕСТ',
    techItems5: 'МОЩНОСТЬ, КВТ/Л.С',
    techItems6: 'МАКСИМАЛЬНАЯ СКОРОСТЬ, КМ/Ч',
    techItems7: 'ПРИВОД',
    techItems8: 'СКОРОСТЬ ЗАРЯДКИ (МЕДЛЕННАЯ/БЫСТРАЯ), ЧАС',
    carGalleryTab1: 'Экстерьер',
    carGalleryTab2: 'Интерьер',
    carGalleryTab3: 'Промо',
    carLinkBtn: 'Подробнее',
    carComp: 'Комплектаций',
    carColor: 'Цветов',
    selectModel: 'Выберите марку',
    selectKm: 'Запас хода',
    selectBattery: 'Емкость батареи',
    selectPrice: 'Цена, $',
    sliderText: 'В НАЛИЧИИ И ПОД ЗАКАЗ',
    sliderKm: 'ЗАПАС ХОДА',
    sliderPower: 'МОЩНОСТЬ',
    sliderP: 'ПРИВОД',
    sliderLoan: 'в кредит от 0,01%',
    shareTextFrom: 'от',
    sharePrice: 'Цена от',
    carFormTitle: 'Заявка на предзаказ',
    carFormFirstName: 'Ваш ФИО',
    carFormPhone: 'Ваш номер телефона',
    carFormComment: 'Ваш комментарий',
    carFormCity: 'Выберите ближайший к вам шоурум',
    carFormComplectaion: 'Комплектация',
    carFormColor: 'Цвет',
    errorMessage: 'Пожалуйста, заполните все обязательные поля',
    gabaritesItem1: 'Объем багажника (л)',
    gabaritesItem2: 'Тип кузова',
    gabaritesItem3: 'Длина (мм)',
    gabaritesItem4: 'Ширина (мм)',
    gabaritesItem5: 'Высота (мм)',
    gabaritesItem6: 'Снаряженная масса (кг)',
    gabH3: 'ГАБАРИТЫ',
    engineH3: 'ДВИГАТЕЛЬ',
    engineItem1: 'Максимальная мощность электродвигателя (л.с.)',
    engineItem2: 'Мощность переднего электродвигателя (кВт)',
    engineItem3: 'Максимальная скорость (км/ч)',
    engineItem4: 'Общая максимальная мощность (кВт)',
    engineItem5: 'Крутящий момент (Нм)',
    engineItem6: 'Количество электромоторов',
    engineItem7: 'Мощность заднего электродвигателя (кВт)',
    engineItem8: 'Тип электродвигателя',
    batteryH3: 'БАТАРЕЯ',
    batteryItem1: 'Емкость батареи (кВт/ч)',
    batteryItem2: 'Быстрая зарядка (часов)',
    batteryItem3: 'Медленная зарядка (часов)',
    batteryItem4: 'Охлаждение батареи',
    batteryItem5: 'Тип батареи',
    batteryItem6: 'Преднагрев батареи',
    safetyItem1: 'Количество подушек безопасности',
    safetyItem2: 'Система контроля давления в шинах (TPMS)',
    safetyItem3: 'абс',
    safetyItem4: 'ЭСП',
    safetyItem5: 'ИСОФИХ',
    safetyItem6: 'Система Auto Hold',
    safetyItem7: 'Система удержания в полосе',
    safetyItem8: 'Система мониторинга усталости водителя',
    safetyItem9: 'Система распознавания дорожных знаков',
    safetyItem10: 'Активный тормоз',
    safetyH3: 'БЕЗОПАСНОСТЬ',
    suspensionAndWheelsItem1: 'Материал колесного диска',
    suspensionAndWheelsItem2: 'Передняя подвеска',
    suspensionAndWheelsItem3: 'Задняя подвеска',
    suspensionAndWheelsItem4: 'Передние тормоза',
    suspensionAndWheelsItem5: 'Задние тормоза',
    suspensionAndWheelsItem6: 'Размер передней резины',
    suspensionAndWheelsItem7: 'Размер задней резины',
    suspensionAndWheelsItem8: 'Стояночный тормоз',
    suspensionAndWheelsItem9: 'Запасное колесо',
    suspensionAndWheelsH3: 'ПОДВЕСКА И КОЛЕСА',
    multimediaItem1: 'Поддержка CarPlay',
    multimediaItem2: 'Приборная панель',
    multimediaItem3: 'Поддержка Android Auto',
    multimediaItem4: 'Центральный экран',
    multimediaItem5: 'Размер центрального экрана (дюйм)',
    multimediaItem6: 'Система навигации GPS',
    multimediaItem7: 'Громкая связь (Hands Free)',
    multimediaItem8: 'Блуэтоотх',
    multimediaItem9: 'Английский язык',
    multimediaH3: 'МУЛЬТИМЕДИА',
    comfortH3: 'КОМФОРТ',
    comfortItem1: 'Бортовой компьютер',
    comfortItem2: 'Источник дальнего света',
    comfortItem3: 'Мульти руль',
    comfortItem4: 'Парктроники',
    comfortItem5: 'Дневные ходовые огни',
    comfortItem6: 'Запуск кнопкой',
    comfortItem7: 'Электропривод сидений',
    comfortItem8: 'Отделка руля ',
    comfortItem9: 'Проекция',
    comfortItem10: 'Количество динамиков',
    comfortItem11: 'Подогрев передних сидений',
    comfortItem12: 'Подсветка салона',
    comfortItem13: 'Электропривод багажника',
    comfortItem14: 'Климат контроль',
    comfortItem15: 'Подогрев задних сидений',
    comfortItem16: 'Подогрев руля',
    comfortItem17: 'Подогрев зеркал',
    comfortItem18: 'Подогрев лобового стекла',
    comfortItem19: 'Сенсор дождя',
    comfortItem20: 'Датчик света',
    comfortItem21: 'Круиз контроль',
    comfortItem22: 'Камера',
    comfortItem23: 'Источник ближнего света',
    comfortItem24: 'Электрозеркала',
    comfortItem25: 'Люк',
    comfortItem26: 'Автосвет',
    comfortItem27: 'Беспроводная зарядка телефона',
    comfortItem28: 'Отделка сидений',
    comfortItem29: 'Память сидений',
    comfortItem30: 'Бесключевой доступ',
    comfortItem31: 'Массаж сидений',
    placeholderName: 'Ваше имя',
    placeholderPhone: 'Ваш номер телефона (включая +374)',
    headerOrder: 'Заказать',
    orderpopupTitle: 'Заявка на запчасти.',
    orderpopupInfo: 'Мы ответим вам в течении 12ч.',
    orderpopUpName: 'Имя',
    orderpopUpPhone: 'Телефон',
    orderpopupMessanger: 'Мессенджер для связи',
    orderpopupMarc: 'Марка и Модель электромобиля',
    orderpopupYear: 'Год выпуска',
    orderpopUpList: 'Список запчастей',
    orderpopsubmit: 'Отправить запрос',
    submitSuccessMessage: 'Ваше сообщение успешно отправлено',
    submitErrorMessage:
      'Ошибка: Не удалось отправить файл. Пожалуйста, попробуйте снова позже.',
  },
};
