import styles from './Table.module.scss';
import {texts} from '../../../../Languages/texts';

function TableMultimedia({ text, carPackages, lang }) {
  const carTitles = carPackages?.map((car, index) => (
    <div key={car.Id} className={styles.column}>
      <div style={{backgroundColor: '#F7F7F8'}} className={styles.title}>{car.Name}</div>
      <div>{car.Multimedia.CarPlaySupport}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car?.Multimedia.Dashboard}</div>
      <div>{car.Multimedia.AndroidAutoSupport}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.Multimedia.CentralScreen}</div>
      <div>{car?.Multimedia?.CenterScreenSize}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.Multimedia.GPSNavigationSystem}</div>
      <div>{car.Multimedia.Speakerphone}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.Multimedia.Bluetooth}</div>
      <div>{car.Multimedia.EnglishLanguage}</div>
    </div>
  ));

  return (
    <div className={styles.container}>
      <div className={styles.tab}>
        <div className={styles.column}>
          <div style={{backgroundColor: '#F7F7F8'}} className={styles.title}>{texts[lang]['multimediaH3']}</div>
          <div>{texts[lang]['multimediaItem1']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['multimediaItem2']}</div>
          <div>{texts[lang]['multimediaItem3']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['multimediaItem4']}</div>
          <div>{texts[lang]['multimediaItem5']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['multimediaItem6']}</div>
          <div>{texts[lang]['multimediaItem7']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['multimediaItem8']}</div>
          <div>{texts[lang]['multimediaItem9']}</div>
        </div>
        {carTitles}
      </div>
      <div>{text}</div>
    </div>
  );
}

export default TableMultimedia;
