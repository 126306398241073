import styles from './ShowMore.module.scss';
import {texts} from '../../../Languages/texts';

function ShowMore({setActiveTab, activeTab, lang}) {
  return (
    <div className={styles.container}>
      <div className={styles.divider} />
      {activeTab === 'Основная информация' && <div className={styles.showMore} onClick={() => setActiveTab('Галерея')}>{texts[lang]['showMore']}</div>}
    </div>
  );
}

export default ShowMore;
