import React, { useState } from 'react';
import { TextField, Button, Paper, CircularProgress, Typography } from '@mui/material'; // Import CircularProgress for loading spinner
import './styles.scss';
import fetchToken from './utils/fetchToken';
import { useAuth } from '../../../Context/AuthProvider';
const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { saveToken } = useAuth();

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!username.trim() || !password.trim()) {
      setError('Please fill in both username and password.');
      return;
    }

    setLoading(true);

    try {
      const token = await fetchToken(username, password);

      if (!token) {
        setError('Login or password is incorrect.');
        return;
      }
      saveToken(token);
      window.location.href = '/p/admin';
    } catch (error) {
      setError('Failed to log in. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <Paper elevation={3} className="login-paper">
        <Typography variant="h5" component="h2" className="login-title">
          Login
        </Typography>
        <form className="login-form" onSubmit={handleLogin}>
          <TextField
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            variant="outlined"
            error={!!error}
            helperText={error}
            disabled={loading}
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            variant="outlined"
            error={!!error}
            helperText={error}
            disabled={loading}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="login-button"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
          </Button>
        </form>
      </Paper>
    </div>
  );
};

export default LoginForm;
