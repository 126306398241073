export const API_BASE_URL = 'https://hayavto.am:8443/HayavtoAPI/';
export const API_ADMIN_URL = 'https://hayavto.am:8443/HayavtoAPI/';
export const adminPages = [
  { title: 'Create', name: 'create' },
  { title: 'Edit', name: 'edit' },
  { title: 'Delete', name: 'delete' },
  { title: 'Available', name: 'isAvailable' },
];

export const adminEditPages = [
  { title: 'General', name: 'general' },
  { title: 'Car Packages', name: 'packages' },
  { title: 'Banners', name: 'banners' },
  { title: 'Gallery', name: 'gallery' },
];


export const initialCarData = {
  name: "",
  model: "",
  isAvailable: true,
  price: 0,
  modifyDate: "",
  mainPicFile: "",
  mainPicFileFormat: "",
  backgroundMiddleImageFile: "",
  backgroundMiddleImageFormat: "",
  carPackages: [
    {
      name: "",
      price: 0,
      batteryCapacity: 0,
      nedc: 0,
      dispersal: 0,
      numberOfSeats: 0,
      power: "",
      maximumSpeed: 0,
      occasionA: "",
      occasionR: "",
      chargingSpeed: "",
      imageFiles: [
        {
          file: "",
          fileFormat: ""
        }
      ],
      battery: {
        batteryCapacity: 0,
        fastCharging: 0,
        slowCharging: 0,
        batteryCooling: "",
        batteryCoolingR: "",
        batteryType: "",
        batteryTypeR: "",
        batteryPreheating: true
      },
      comfort: {
        onBoardComputer: true,
        highBeamSource: "",
        highBeamSourceR: "",
        multiSteeringWheel: true,
        parktronics: "",
        parktronicsR: "",
        daytimeRunningLights: true,
        startButton: true,
        electricSeatsA: "",
        electricSeatsR: "",
        steeringWheelTrim: "",
        steeringWheelTrimR: "",
        projection: true,
        numberOfSpeakers: 0,
        heatedFrontSeats: true,
        interiorLighting: "",
        interiorLightingR: "",
        electricTrunkDrive: true,
        climateControl: "",
        climateControlR: "",
        heatedRearSeats: true,
        heatedSteeringWheel: true,
        heatedMirrors: true,
        heatedWindshield: true,
        rainSensor: true,
        lightSensor: true,
        cruiseControl: "",
        cruiseControlR: "",
        camera: "",
        lowBeamSource: "",
        lowBeamSourceR: "",
        electricMirror: "",
        electricMirrorR: "",
        lukeA: "",
        lukeR: "",
        autosvet: true,
        wirelessPhoneCharging: true,
        seatTrim: "",
        seatTrimR: "",
        seatMemory: "",
        seatMemoryR: "",
        keylessEntry: true,
        seatMassage: "",
        seatMassageR: ""
      },
      dimension: {
        trunkVolume: "",
        trunkVolumeR: "",
        bodyType: "",
        bodyTypeR: "",
        length: 0,
        width: 0,
        height: 0,
        curbWeight: 0
      },
      engine: {
        maxMotorPower: 0,
        frontMotorPower: 0,
        maximumSpeed: 0,
        maxTotalPower: 0,
        torque: 0,
        numberOfElectricMotors: 0,
        rearMotorPower: 0,
        enginesType: "",
        enginesTypeR: ""
      },
      multimedia: {
        carPlaySupport: true,
        dashboard: "",
        dashboardR: "",
        androidAutoSupport: true,
        centralScreen: "",
        centralScreenR: "",
        centerScreenSize: 0,
        gpsNavigationSystem: true,
        speakerphone: true,
        bluetooth: true,
        englishLanguage: true
      },
      safety: {
        numberOfAirbags: 0,
        tpms: true,
        abs: true,
        esc: true,
        isofix: true,
        autoHoldSystem: true,
        laneKeepingSystem: true,
        driverFatigueMonitoringSystem: true,
        trafficSignRecognitionSystem: true,
        activeBrake: true
      },
      suspensionAndWheel: {
        wheelRimMaterial: "",
        wheelRimMaterialR: "",
        frontSuspension: "",
        frontSuspensionR: "",
        rearSuspension: "",
        rearSuspensionR: "",
        frontBrakes: "",
        frontBrakesR: "",
        rearBrakes: "",
        rearBrakesR: "",
        frontTireSize: "",
        frontTireSizeR: "",
        rearTireSize: "",
        rearTireSizeR: "",
        parkingBrake: "",
        parkingBrakeR: "",
        spareWheel: true
      }
    }
  ],
  galleryImages: [
    {
      file: "",
      fileFormat: "",
      color: "",
      position: 1
    }
  ],




  baseDescription: {
    file: "",
    fileFormat: "",
    ttileA: "",
    ttileR: "",
    bodyA: "",
    bodyR: "",
    isBase: true
  },
  firstDescription: {
    file: "",
    fileFormat: "",
    ttileA: "",
    ttileR: "",
    bodyA: "",
    bodyR: "",
    isBase: true
  },
  secondDescription: {
    file: "",
    fileFormat: "",
    ttileA: "",
    ttileR: "",
    bodyA: "",
    bodyR: "",
    isBase: true
  },
  banners: [
    {
      ttileA: "",
      ttileR: "",
      file: "",
      fileFormat: ""
    }
  ]
};


// constants.js
