import React from 'react';
import styles from './UserCard.module.scss';
import {Link} from 'react-router-dom';
import {texts} from '../../Languages/texts';

const UserCard = ({ picture, lang, description, isAvailable }) => {
  return (
    <div className={styles.userCard}>
      <img src={picture} alt="Picture" className={styles.profileImg} />
      <div className={styles.userInfo}>
        <h2 className={styles.title}>{texts[lang]['orderInfoTitle']}</h2>
        {/*<p className={styles.description}>{description}</p>*/}
        <p className={styles.description}>{description.text1}</p>
        <p className={styles.description}>{description.text2}</p>
        <p className={styles.description}>{description.text3}</p>
        <p className={styles.description}>{description.text4}</p>
        {isAvailable ?
          <Link className={styles.linkStyles} to={`/available`}>
            {texts[lang]['orderInfoBtnText']}
          </Link> :
          <Link className={styles.linkStyles} to={`/all`}>
            {texts[lang]['orderInfoBtnText']}
          </Link>
        }
      </div>
    </div>
  )
};
export default UserCard;
