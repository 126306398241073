import styles from './Table.module.scss';
import {texts} from '../../../../Languages/texts';

function TableBattery({ text, carPackages, lang }) {
  const carTitles = carPackages?.map((car, index) => (
    <div key={car.Id} className={styles.column}>
      <div style={{backgroundColor: '#F7F7F8'}} className={styles.title}>{car.Name}</div>
      <div>{car.Battery.BatteryCapacity}</div>
        <div style={{backgroundColor: '#F7F7F8'}}>{car?.Battery?.FastCharging}</div>
      <div>{car.Battery.SlowCharging}</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.Battery.BatteryCooling }</div>
      <div>{car?.Battery?.BatteryType  }</div>
      <div style={{backgroundColor: '#F7F7F8'}}>{car.Battery.BatteryPreheating }</div>
    </div>
  ));

  return (
    <div className={styles.container}>
      <div className={styles.tab}>
        <div className={styles.column}>
          <div style={{backgroundColor: '#F7F7F8'}} className={styles.title}>{texts[lang]['batteryH3']}</div>
          <div>{texts[lang]['batteryItem1']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['batteryItem2']}</div>
          <div>{texts[lang]['batteryItem3']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['batteryItem4']}</div>
          <div>{texts[lang]['batteryItem5']}</div>
          <div style={{backgroundColor: '#F7F7F8'}}>{texts[lang]['batteryItem6']}</div>
        </div>
        {carTitles}
      </div>
      <div>{text}</div>
    </div>
  );
}

export default TableBattery;
