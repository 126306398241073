import { useEffect, useState } from 'react';
import styles from './Selects.module.scss';
import { ReactComponent as TwoColum } from './img/twoColumn.svg'
import { ReactComponent as ThreeColum } from './img/threeColumn.svg'
import cx from 'classnames';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { texts } from "../../../Languages/texts";

function Selects({ setIsTwoColum, isTwoColum, setSelectedCar, setSelectedOptions, selectedOptions, lang }) {
  const location = useLocation();
  const [brand, setBrand] = useState('');
  const [brandList, setBrandList] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const model = queryParams?.get('model')
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    if (model) {
      setBrand(model)
      setSelectedCar(model)
    }
  }, [model])


  useEffect(() => {
    getAllCarsModels();
  }, [])

  const getAllCarsModels = async () => {
    try {
      const response = await axios.get('https://hayavto.am:8443/HayavtoAPI/models', {
        headers: {
          'Authorization': 'Basic 2f8061c0a41ee7d6e7d302d03038e6f5502c77678a67bbcb4a52f9608ec225bf',
        },
      });
      setBrandList(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle the error (e.g., show an error message, retry the request, etc.)
    }
  };

  function handleChange(e) {
    setSelectedCar(e.target.value)
    setBrand(e.target.value)

    const url = new URL(window.location.href);
    queryParams.delete('model');
    url.search = queryParams.toString();
    window.history.replaceState({}, '', url);
  }

  const handleSelectChange = (name, value) => {
    setSelectedOptions({
      ...selectedOptions,
      [name]: value
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.selectsWrapper}>
        <select value={brand} onChange={handleChange}>
          <option value="" disabled hidden>{texts[lang]['selectModel']}</option>
          {brandList?.map((brandOption, index) => (
            <option key={index} value={brandOption}>{brandOption}</option>
          ))}
        </select>
        {/* --------------Need to implement-----------------*/}
        {/*<select value={bodyType} placeholder="Выберите тип кузова" onChange={(e) => setBodyType(e.target.value)}>*/}
        {/*  <option value="" disabled hidden>Выберите тип кузова</option>*/}
        {/*  <option value="type1">Comming Soon</option>*/}
        {/*</select>*/}
        <select value={selectedOptions.NEDC === '0-1000000' ? '' : selectedOptions.NEDC} placeholder="Запас хода" onChange={(e) => handleSelectChange('NEDC', e.target.value)}>
          <option value="0-1000000">{texts[lang]['selectKm']}</option>
          <option value="0-30">до 250 км</option>
          <option value="250-400">250 - 400</option>
          <option value="400-550">400 - 550</option>
          <option value="550-1000">больше 550 км</option>
        </select>

        <select value={selectedOptions.BatteryCapacity === '0-1000000' ? '' : selectedOptions.BatteryCapacity} placeholder="Емкость батареи" onChange={(e) => handleSelectChange('BatteryCapacity', e.target.value)}>
          <option value="0-1000000">{texts[lang]['selectBattery']}</option>
          <option value="0-30"> до 30 кВт</option>
          <option value="30-55">30-55 кВт</option>
          <option value="55-80">55-80 кВт</option>
          <option value="80-500">больше 80 кВт</option>
        </select>

        <select value={selectedOptions.Price === '0-1000000' ? '' : selectedOptions.Price} placeholder="Цена, $" onChange={(e) => handleSelectChange('Price', e.target.value)}>
          <option value="0-1000000">{texts[lang]['selectPrice']}</option>
          <option value="0-20000">до 20000</option>
          <option value="20000 - 30000">20000 - 30000</option>
          <option value="30000 - 40000">30000 - 40000</option>
          <option value="40000 - 50000">40000 - 50000</option>
          <option value="50000 - 60000">50000 - 60000</option>
          <option value="60000-900000">больше 60000</option>
        </select>
        {!isMobile &&
          <>
            <div className={cx(styles.toggles, { [styles.togglesTwoColum]: isTwoColum })}
              onClick={() => setIsTwoColum(true)}>
              <TwoColum />
            </div>
            <div className={cx(styles.toggles, { [styles.togglesTwoColum]: !isTwoColum })} onClick={() => setIsTwoColum(false)} >
              <ThreeColum />
            </div>
          </>
        }
      </div>

    </div>
  );
}

export default Selects;
