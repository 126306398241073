export const colors = [
  {
    "HUNTING GREEN/BLACK": "#97A8A1/#000100",
  },
  {
    "BLUE/WHITE": "#3D4DA6/#FCFFFC",
  },
  {
    "GRAY WHITE": "#969696/#FEFEFE",
  },
  {
    "HUNTING GREEN": "#566655"
  },
  {
    "RED BLACK": "#F62D4A",
  },
  {
    "THE BLACKEST NIGHT": "#000000",
  },
  {
    "GLACIAL SILVER (FR)": "#BFCCCE",
  },
  {
    "STAR SILVER": "#B6B6B6",
  },
  {
    "STAR BLUE": "#0832A3",
  },
  {
    "POLARIS BLUE/BLACK": "#1A2A43/#020000",
  },
  {
    "LASER GREY/BLACK": "#C8CBD2/#000100",
  },
  {
    "HARNESS ORANGE/BLACK": "#f98c61/#020000",
  },
  {
    "POLAR WHITE/BLACK": "#FFFFFF/#010101",
  },
  {
    "DARK BLUE/WHITE": "#2E385C/#FCFEFF",
  },
  {
    "BLACK/GREY": "#000000/#B6B6B6",
  },
  {
    "ORANGE/GREY": "#E97547/#A0A6A8",
  },
  {
    "ATHENS CLOUD WHITE": "#ffffff",
  },
  {
    "SYDNEY COVE BLUE": "#3a6572"
  },
  {
    "HANG GREEN": "#779576"
  },
  {
    "CALIFORNIA POWDER": "#d5c0c7"
  },
  {
    "BERLIN SILVER GRAY": "#b6b6b6"
  },
  {
    "PARIS BROWN RICE": "#cdcec7"
  },
  {
    "DAWN BROWN/SILVER": "#b69189/#d0ceca"
  },
  {
    "CLEAR SKY BLUE/SILVER": "#5a6e8e/#9ea0a4"
  },
  {
    "MISTY GRAY": "#b7bcc8"
  },
  {
    "EXTREME NIGHT BLACK": "#000000"
  },
  {
    "CLOUD SILVER": "#e3e3e3"
  },
  {
    "BRIGHT MOON WHITE": "#ffffff"
  },
  {
    "GREY/BLUE": "#cdc4c7/#2e385b"
  },
  {
    "PURPLE ROSE": "#821656"
  },
  {
    "BLUE/GREEN": "#2f375b/#3a6574"
  },
  {
    "BLACK/GRAY": "#000000/#b6b6b6"
  },
  {
    "CHEESE YELLOW": "#fbfce5"
  },
  {
    "ATLANTIS GREY": "#bccce7"
  },
  {
    "PUFF POWDER": "#c59da4"
  },
  {
    "MILK TEA BROWN": "#e7e3d0"
  },
  {
    "THE DARKNESS OF NIGHT": "#000000"
  },
  {
    "CRYSTAL WHITE": "#ffffff"
  },
  {
    "FRENCH RED": "#f40001"
  },
  {
    "BEBE GRAY": "#b6b6b6"
  },
  {
    "AURORA BLUE": "#0131a5"
  },
  {
    "TWILIGHT MOUNTAIN VIOLET": "#8f9ebf"
  },
  {
    "SNOWY WHITE": "#ffffff"
  },
  {
    "TIME GRAY": "#7d7d7d"
  },
  {
    "CHIDIHONG": "#f40001"
  },
  {
    "GLACIER BLUE": "#bed0ee"
  },
  {
    "XUANKONG BLACK": "#000000"
  },
  {
    "LOTUS ROOT GREY": "#c3c7c8"
  },
  {
    "UNICORN BROWN": "#e7e3d0"
  },
  {
    "GOLDEN SCALE ORANGE": "#ff6c01"
  },
  {
    "XINGYUN BLUE": "#5b7cb0"
  },
  {
    "SUNNY BROWN/BEIGE": "#786b69/#cfc4c3"
  },
  {
    "PEACH POWDER": "#efdbdb"
  },
  {
    "BUDDING GREEN": "#def52e"
  },
  {
    "WARM SUN WHITE": "#ffffff"
  },
  {
    "POLAR NIGHT BLACK": "#000000"
  },
  {
    "DUNE POWDER": "#efdbdb"
  },
  {
    "LIGHT SEA BLUE": "#bed0ee"
  },
  {
    "DARK BLUE": "#012367"
  },
  {
    "WINDSNAP GREEN": "#8c9985"
  },
  {
    "ARCTIC BLUE": "#bed0ee"
  },
  {
    "AURORA WHITE": "#ffffff"
  },
  {
    "ATLANTIS GRAY": "#5b7693"
  },
  {
    "ROSEMARY GRAY": "#c3c8d4"
  },
  {
    "TAMAS BLACK": "#000000"
  },
  {
    "TAMAS BLACK": "#dee9fa"
  },
  {
    "TEHRAN": "#000000"
  },
  {
    "SMOKY": "#cdcec7"
  },
  {
    "AZURE": "#bed0ee"
  },
  {
    "MOUNTAIN GRAY": "#7d7d7d"
  },
  {
    "SILVER GLAZE WHITE": "#dee4f4"
  },
  {
    "LOTUS ROOT RHYME GRAY": "#e3e3e3"
  },
  {
    "SKY GRAY": "#3e3e3e"
  },
  {
    "ADVENTURE GREEN": "#779576"
  },
  {
    "SURF BLUE": "#357ede"
  },
  {
    "OXYGEN BLUE": "#bed0ee"
  },
  {
    "RHYTHMIC PURPLE": "#7e798b"
  },
  {
    "SKY WHITE": "#ffffff"
  },
  {
    "CLIMBING GRAY": "#7d7d7d"
  },
  {
    "VITALITY POWDER": "#c59da4"
  },
  {
    "VITALITY WHITE": "#ffffff"
  },
  {
    "FLASH YELLOW": "#ffeb00"
  },
  {
    "NEBULA BLUE": "#87dfdf"
  },
  {
    "PLATINUM WHITE": "#ffffff"
  },
  {
    "MICA RED/MOYUAN BLACK": "#b01a19/#050000"
  },
  {
    "STREAMER SILVER": "#b6b6b6"
  },
  {
    "MO YUANHEI": "#000000"
  },
  {
    "CRYSTAL GRAY": "#7d7d7d"
  },
  {
    "SILK BROWN": "#aa8c82"
  },
  {
    "PLATINUM WHITE/ABYSSAL BLACK": "#000000/#ffffff"
  },
  {
    "SILKY BROWN/DARK BLACK": "#d1b6aa/#000000"
  },
  {
    "ELEGANT GRAY": "#e3e3e3"
  },
  {
    "TWILIGHT GOLD/BLACK": "#d1b6aa/#000000"
  },
  {
    "BRIGHT MOON SILVER": "#7d7d7d"
  },
  {
    "TWILIGHT GOLD": "#8d827d"
  },
  {
    "VAST BLACK": "#000000"
  },
  {
    "NEBULA RED/BLACK": "#ff0002/#000000"
  },
  {
    "PURE WHITE/BLACK": "#ffffff/#000000"
  },
  {
    "PURE WHITE": "#ffffff"
  },
  {
    "NEBULA RED": "#f40001"
  },
  {
    "COOL BLACK": "#000000"
  },
  {
    "LIGHT LUXURY GRAY": "#7d7d7d"
  },
  {
    "STYLE RED": "#8d3231"
  },
  {
    "STARBURST WHITE": "#ffffff"
  },
  {
    "IONIC GREY": "#9d9699"
  },
  {
    "MOON GOLDEN STAR": "#ffffff"
  },
  {
    "PHANTOM NIGHT BLACK KNIGHT": "#000000"
  },
  {
    "QUANTUM GOLD": "#e8d8bd"
  },
  {
    "ETHER RED": "#f40001"
  },
  {
    "PEARL WHITE": "#ffffff"
  },
  {
    "GALAXY GRAY": "#b6b6b6"
  },
  {
    "BLACK/BEIGE": "#000000/#b4b4b4"
  },
  {
    "GRAY/BLACK": "#b6b6b6/#000000"
  },
  {
    "ENERGY BLACK": "#000000"
  },
  {
    "ION GRAY": "#7d7d7d"
  },
  {
    "CARBON BLACK": "#000000"
  },
  {
    "GALAXY BLUE": "#7a869b"
  },
  {
    "SUPERCONDUCTING RED": "#f40001"
  },
  {
    "MINT GREEN": "#c1e6ee"
  },
  {
    "COOL BLACK WAVE": "#000000"
  },
  {
    "URBAN PASSION RED": "#ff0001"
  },
  {
    "FRESH IDEA BLUE": "#c1e6ee"
  },
  {
    "TREND COORDINATES GRAY": "#b7bcc8"
  },
  {
    "GAIA ORANGE": "#ff6c01"
  },
  {
    "PEARL WHITE/BLACK": "#ffffff/#000000"
  },
  {
    "STAR BLUE/BLACK": "#1c2e65/#000000"
  },
  {
    "EVENING GOLD": "#8d827d"
  },
  {
    "NEBULA PURPLE": "#5b5762"
  },
  {
    "PEARL WHITE": "#ffffff"
  },
  {
    "STRONG TREND GREY": "#7d7d7d"
  },
  {
    "URBAN ENERGY BLACK": "#33333e"
  },
  {
    "HOJO PURPLE": "#7e798b"
  },
  {
    "BEIGE": "#e7e3d0"
  },
  {
    "BEIGE/BROWN": "#786b69/#e7e3d0"
  },
  {
    "LIGHT BLUE": "#7ba4bd"
  },
  {
    "INK BLACK": "#000000"
  },
  {
    "MAPLE LEAF RED/BLACK": "#b01a19/#000000"
  },
  {
    "CYPRESS BLUE/BLACK": "#027bb4/#000000"
  },
  {
    "STARLET BLUE/BLACK": "#50657e/#000000"
  },
  {
    "SPRUCE GREEN/BLACK": "#067d87/#000000"
  },
  {
    "STARBURST BLACK": "#000000"
  },
  {
    "TOP PEARL WHITE/BLACK INK": "#ffffff/#000000"
  },
  {
    "SPRUCE GREEN": "#3a6572"
  },
  {
    "SONIC TWILIGHT BRONZE": "#bb6036"
  },
  {
    "SONIC QUARTZ WHITE": "#ffffff"
  },
  {
    "ETHER BLUE": "#7ba4bd"
  },
  {
    "SUPERSONIC STREAMER METALLIC": "#3e3e3e"
  },
  {
    "SUPERSONIC IRIDIUM SILVER": "#7d7d7d"
  },
  {
    "BI-TONE EXTREME TWILIGHT": "#bd6034"
  },
  {
    "BI-TONE SEEKS ETHER": "#bed0ee"
  },
  {
    "ULTIMATE BLACK": "#000000"
  },
  {
    "LAYER OF INK GRAY": "#b6b6b6"
  },
  {
    "STORM BLUE": "#3d4d6c"
  },
  {
    "AKIYAMA CHESTNUT": "#9a9589"
  },
  {
    "SONIC QUARTZ WHITE": "#ffffff"
  },
  {
    "FLAME ONYX": "#ff6c01"
  },
  {
    "DARK BLUE MICA COLOR": "#707c7a"
  },
  {
    "BLUE SKY": "#0131a5"
  },
  {
    "SUPERSONIC TITANIUM SILVER": "#7d7d7d"
  },
  {
    "GLASS BLUE/WHITE": "#2f375b/#ffffff"
  },
  {
    "OCHER CRYSTAL BROWN/BLACK": "#ad9284/#000000"
  },
  {
    "SNOWY WHITE": "#ffffff"
  },
  {
    "XIAGUANG POWDER": "#efdbdb"
  },
  {
    "FIELD GREEN": "#cedac6"
  },
  {
    "GREEN/GRAY": "#475e6b/#d0cecc"
  },
  {
    "BLUE/GRAY": "#5a6d8d/#9ea0a4"
  },
  {
    "PINK": "#efdbdb"
  },
  {
    "LAKE BLUE": "#357ede"
  },
  {
    "FOG WHITE": "#ffffff"
  },
  {
    "CHERRY POWDER": "#d5c0c7"
  },
  {
    "MOSS GREEN": "#87dfdf"
  },
  {
    "MATTE BAMBOO GREEN": "#86a5a8"
  },
  {
    "PURPLE IN THE DISTANCE": "#e1baba"
  },
  {
    "FUJITSUGUIN": "#beaf67"
  },
  {
    "PALE GREY": "#3d3d3d"
  },
  {
    "CULTIVATE BAMBOO": "#398d91"
  },
  {
    "MISTY GREEN": "#a1a7ab"
  },
  {
    "REDDISH-RED": "#ac6c35"
  },
  {
    "CLOUDY GREY": "#e3e3e3"
  },
  {
    "THE WHOLE WORLD IS BLACK": "#000000"
  },
  {
    "MARS ORANGE": "#e8d8bd"
  },
  {
    "STAR CURTAIN GRAY": "#b7bcc8"
  },
  {
    "HAOYUEBAI": "#ffffff"
  },
  {
    "WILDERNESS STARRY SKY": "#b6afb1"
  },
  {
    "WHITE SAND BEACH": "#f8f9f2"
  },
  {
    "TAHITIAN BLUE": "#dee9fa"
  },
  {
    "SAPPHIRE BLUE": "#87dfdf"
  },
  {
    "COSMIC BLACK": "#000000"
  },
  {
    "MOUNTAIN ASH": "#7a869b"
  },
  {
    "ARCTIC WHITE": "#ffffff"
  },
  {
    "HAO SHA SILVER": "#cdcec7"
  },
  {
    "MACCHIATO BEIGE/BLACK": "#e8e2d0/#000000"
  },
  {
    "SEA ​​NIGHT BLUE": "#0131a5"
  },
  {
    "FRITILLARY WHITE": "#ffffff"
  },
  {
    "TIME AND SPACE SILVER": "#c3c8d4"
  },
  {
    "SODALITE BLUE": "#3d4d6c"
  },
  {
    "OBSIDIAN BLACK": "#000000"
  },
  {
    "YUANFENG GRAY": "#b6b6b6"
  },
  {
    "BLACK/SPACE GRAY": "#000000/#b6b6b6"
  },
  {
    "NEBULA GRAY/RAY BLUE": "#b7b8bd/#456ac9"
  },
  {
    "NEBULA GRAY/BROWN": "#b8b7be/#8d7a55"
  },
  {
    "BLACK/RAY BLUE": "#000000/#628fe6"
  },
  {
    "VELVET BROWN": "#9a9589"
  },
  {
    "BLACK/BISCAY BLUE": "#000000/#2943d5"
  },
  {
    "NEBULA GRAY/BALAU BROWN": "#786b69/#cfc4c3"
  },
  {
    "BALAU BROWN/BLACK": "#ac9382/#000000"
  },
  {
    "NEBULA GRAY/BISCAY BLUE": "#dcd5d7/#2943d5"
  },
  {
    "DIAMOND WHITE": "#ffffff"
  },
  {
    "GRAPHITE GRAY": "#3e3e3e"
  },
  {
    "BLACK/BROWN": "#000000/#3f351f"
  },
  {
    "BLUE/SPACE GRAY": "#5f6b8b/#9ea0a4"
  },
  {
    "STONE GREEN": "#253023"
  },
  {
    "BLACK/BLUE": "#000000/#1b2e63"
  },
  {
    "SPACE TIME SILVER": "#b7bcc8"
  },
  {
    "RUBY GOLD": "#aa8c82"
  },
  {
    "LAVA RED": "#f40001"
  },
  {
    "COAST BLUE": "#357ede"
  },
  {
    "MINERAL WHITE": "#ffffff"
  },
  {
    "SKYSCRAPER GRAY": "#7d7d7d"
  },
  {
    "VOLCANO RED": "#b01a19"
  },
  {
    "MOCHA": "#77473c"
  },
  {
    "GOLD DUST RED": "#6b0301"
  },
  {
    "SUNSET ORANGE": "#c4362b"
  },
  {
    "BROOKLYN GRAY": "#bbc7d1"
  },
  {
    "TOURMALINE GRAY": "#3e3e3e"
  },
  {
    "FROSTED PORTIMAO BLUE": "#0131a5"
  },
  {
    "GEM BLUE": "#000000"
  },
  {
    "SAN REMO GREEN": "#253023"
  },
  {
    "PARTICLE BLUE": "#5b7cb0"
  },
  {
    "TANZANITE BLUE": "#012367"
  },
  {
    "LIMESTONE/ALLUVIAL STONE RED": "#454545/#f40003"
  },
  {
    "FROSTED PURE GRAY": "#c3c8d4"
  },
  {
    "SHINY COPPER GRAY": "#b7bcc8"
  },
  {
    "FROSTED TANZANITE BLUE": "#012367"
  },
  {
    "MATTE DARK GRAY": "#3e3e3e"
  },
  {
    "SPACE SILVER": "#e3e3e3"
  },
  {
    "SMOKE WHITE": "#ffffff"
  },
  {
    "AMARYEN RED": "#8d3231"
  },
  {
    "BURGUNDY/BLACK": "#000000/#ff0001"
  },
  {
    "STORM GRAY": "#7d7d7d"
  },
  {
    "BLUE RIDGE MOUNTAINS": "#3a6572"
  },
  {
    "MYSTERY GRAY": "#7d7d7d"
  },
  {
    "ROCK ASH": "#cdcec7"
  },
  {
    "CHESTNUT": "#bb6036"
  },
  {
    "STONE GRAY": "#b6b6b6"
  },
  {
    "ORE WHITE": "#c1c1c1"
  },
  {
    "SPARKLING COPPER GREY": "#4b6382"
  },
  {
    "SOOT": "#4c4c4c"
  },
  {
    "SKYSCRAPER GREY": "#75777b"
  },
  {
    "FERROUS": "#3b3b3b"
  },
  {
    "CORAL RED/BLACK": "#e44649/#000000"
  },
  {
    "QUANTUM BLUE": "#012367"
  },
  {
    "BLACK": "#000000"
  },
  {
    "BLUE": "#BED0EE"
  },
  {
    "GRAY": "#808080"
  },
  {
    "BERE GRAY": "#CFCFCF"
  },
  {
    "FOG GREEN": "#CFD9CF"
  },
  {
    "SUNSHINE AND GOLD": "#FFDF00"
  },
  {
    "YAO GRAY": "#BEBEBE"
  }
]
