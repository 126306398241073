import { useCallback, useEffect, useState } from 'react';
import Title from './Title/Title';
import ModelCard from '../CarConnected/ModelCard/ModelCard';
import Selects from './Selects/Selects';
import styles from './AllPage.module.scss';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

function AllPage({ setIsTwoColum, isTwoColum, json, setJson, lang }) {
  const [selectedCar, setSelectedCar] = useState('');
  const location = useLocation();
  const [selectedOptions, setSelectedOptions] = useState({
    BatteryCapacity: '0-1000000',
    NEDC: '0-1000000',
    Price: '0-1000000',
  });
  const [data, setData] = useState([])

  const getAllCars = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://hayavto.am:8443/HayavtoAPI/Car/GetOrdersAssortment`,
        {
          headers: {
            Authorization:
              'Basic 2f8061c0a41ee7d6e7d302d03038e6f5502c77678a67bbcb4a52f9608ec225bf',
          },
        }
      );
      setData(response.data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);

  useEffect(() => {
    if (selectedCar) {
      getAllCarsByModel(selectedCar);
    }
  }, [selectedCar]);

  const getAvailableCars = async (model) => {
    try {
      const response = await axios.get(
        `https://hayavto.am:8443/HayavtoAPI/Car/GetAvailable`,
        {
          headers: {
            Authorization:
              'Basic 2f8061c0a41ee7d6e7d302d03038e6f5502c77678a67bbcb4a52f9608ec225bf',
          },
        }
      );
      setJson(response.data);
      setData(response.data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    (async () => {
      if (location.pathname === '/available') {
        await getAvailableCars();
        return;
      } else {
        await getAllCars();
      }
    })()
  }, [location.pathname]);

  const getAllCarsByModel = async (model) => {
    try {
      const response = await axios.get(
        `https://hayavto.am:8443/HayavtoAPI/carModels?model=${model}`,
        {
          headers: {
            Authorization:
              'Basic 2f8061c0a41ee7d6e7d302d03038e6f5502c77678a67bbcb4a52f9608ec225bf',
          },
        }
      );
      setJson(response.data);
      setData(response.data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className={styles.container}>
      <Title lang={lang} />
      <Selects
        setIsTwoColum={setIsTwoColum}
        isTwoColum={isTwoColum}
        setSelectedCar={setSelectedCar}
        setSelectedOptions={setSelectedOptions}
        selectedOptions={selectedOptions}
        lang={lang}
      />
      <div className={styles.allCars}>
        <ModelCard
          isTwoColum={isTwoColum}
          json={data}
          selectedCar={selectedCar}
          selectedOptions={selectedOptions}
          lang={lang}
        />
      </div>
    </div>
  );
}

export default AllPage;
