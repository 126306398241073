import { API_BASE_URL } from "../../../../constants";

const fetchToken = async (email, password) => {
  try {
    const response = await fetch(`https://hayavto.am:8443/HayavtoAPI/Authentication/Login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    }
    );
    if (!response.ok) {
      throw new Error('Failed to fetch token');
    }
    const token = await response.text();
    return token;
  } catch (error) {
    console.error('Error fetching token:', error.message);
    return null;
  }
};

export default fetchToken;
