import styles from './SliderText.module.scss';

function SliderText({ res, lang }) {
  return (
    <div className={styles.container}>
      <div>{res.Name}</div>
    </div>
  );
}

export default SliderText;
