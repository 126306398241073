import styles from './MenuNav.module.scss';
import { ReactComponent as Instagram } from './Images/insta.svg';
import { ReactComponent as Fb } from './Images/fb.svg';
import logo from './Images/boldLogo.png';
import whatsApp from './Images/whatsapp.png';
import { Link } from 'react-router-dom';
import { texts } from '../../../Languages/texts';
import { useState } from 'react';
import FullScreenModal from '../../ModalPopUp/ModalPopUp';

function MenuNav({ setIsOpenedNav, lang }) {
  const handleClick = () => {
    setIsOpenedNav(false);
  };
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.logoWrapper}>
        <img className={styles.logo} src={logo} />
      </div>
      <Link className={styles.linkStyles} to={`/all`} onClick={handleClick}>
        {texts[lang]['menuItem1']}
      </Link>
      <Link
        className={styles.linkStyles}
        to={`/available`}
        onClick={handleClick}
      >
        {texts[lang]['menuItem2']}
      </Link>
      <Link className={styles.linkStyles} to={`/home`} onClick={handleClick}>
        {texts[lang]['menuItem3']}
      </Link>
      <div
        style={{ display: 'flex', justifyContent: 'flex-start' }}
        onClick={() => setModalOpen(true)}
      >
        {texts[lang]['headerOrder']}
      </div>
      <div className={styles.menuFooter}>
        <a href='tel:+374 44-17-19-17'>+374 44-17-19-17</a>
        <div className={styles.social}>
          <a
            href='https://www.instagram.com/hayavto?igsh=b2t1MWwweGtkcWlv&utm_source=qr'
            target='_blank'
          >
            <Instagram />
          </a>
          <a target='_blank' href='https://wa.me/+37444171917'>
            <img src={whatsApp} style={{ width: '32px', height: '32px' }} />
          </a>
          <a
            href='https://www.facebook.com/hayavto?mibextid=sCpJLy'
            target='_blank'
          >
            <Fb />
          </a>
        </div>
      </div>
      {modalOpen && (
        <FullScreenModal
          isOpen={modalOpen}
          setIsOpen={setModalOpen}
          lang={lang}
        />
      )}
    </div>
  );
}

export default MenuNav;
