import React, { useState } from 'react';
import styles from './Gallery.module.scss';
import Exterior from './Exterior/Exterior';
import Interior from './Interior/Interior';
import Promo from './Promo/Promo';
import { texts } from '../../../Languages/texts';

function Galleryx({ res, lang }) {
  const [activeTab, setActiveTab] = useState('Exterior');
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <div
          onClick={() => handleTabClick('Exterior')}
          className={activeTab === 'Exterior' ? styles.active : ''}
        >
          {texts[lang]['carGalleryTab1']}
        </div>
        <div
          onClick={() => handleTabClick('Interior')}
          className={activeTab === 'Interior' ? styles.active : ''}
        >
          {texts[lang]['carGalleryTab2']}
        </div>
        <div
          onClick={() => handleTabClick('Promo')}
          className={activeTab === 'Promo' ? styles.active : ''}
        >
          {texts[lang]['carGalleryTab3']}
        </div>
      </div>
      <div className={styles.gallery}>
        {activeTab === 'Exterior' && <Exterior photos={res?.Exterior} />}
        {activeTab === 'Interior' && <Interior photos={res?.Interior} />}
        {activeTab === 'Promo' && <Promo photos={res?.Promo[0]} />}
      </div>
    </div>
  );
}

export default Galleryx;
