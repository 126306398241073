import React from 'react';
import Carousel from '../Carousel/Carousel';
import MainHeader from '../MainHeader/Mainheader';
import { images, mobileImages } from '../../constants/common';
function Header({ setIsOpenedNav, setLang, lang }) {
  const isMobile = window.innerWidth <= 768;
  const imgProps = isMobile ? mobileImages : images;
  return (
    <div>
      <MainHeader
        setIsOpenedNav={setIsOpenedNav}
        setLang={setLang}
        lang={lang}
      />
      <Carousel images={imgProps} />
    </div>
  );
}

export default Header;
