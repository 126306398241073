import React, { useState, useEffect } from 'react';
import Gallery from 'react-photo-gallery';
import { colors } from '../color';

import styles from './Exterior.module.scss';
import ImageFullScreenCarousel from '../../../ImageFullScreenCarousel/ImageFullScreenCarousel';

function Exterior({ photos }) {
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);
  const [initialIndex, setInitialIndex] = useState(0);
  const closeModal = () => {
    setIsCarouselOpen(false);
  };
  const [activeTab, setActiveTab] = useState(
    photos?.length ? photos[0]?.ColorName : null
  );

  const handleTabClick = (ColorName) => {
    setActiveTab(activeTab === ColorName ? null : ColorName);
  };

  function findColorValue(colorName) {
    const colorEntry = colors.find(
      (entry) => Object.keys(entry)[0] === colorName
    );
    if (!colorEntry) return;

    const colorValue = Object.values(colorEntry);
    if (colorValue[0].includes('/')) {
      const [firstColor, secondColor] = colorValue[0].split('/');
      return `linear-gradient(to right, ${firstColor}, ${secondColor})`;
    } else {
      return colorValue[0];
    }
  }
  const handleImageClick = (photoIndex, imageUrl) => {
    const imageIndex = photos[photoIndex].Images.findIndex(
      (image) => image.src === imageUrl
    );
    setInitialIndex(imageIndex);
    setIsCarouselOpen(true);
  };

  useEffect(() => {
    if (isCarouselOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isCarouselOpen]);

  return (
    <div className={styles.container}>
      {isCarouselOpen && (
        <ImageFullScreenCarousel
          images={photos
            .filter((photo) => activeTab === photo.ColorName)
            .flatMap((photo) => photo.Images)}
          initialIndex={initialIndex}
          isOpen={isCarouselOpen}
          closeModal={closeModal}
        />
      )}
      <div className={styles.tabs}>
        {photos?.map((photo, index) => (
          <div
            key={index}
            className={`${styles.tab} ${activeTab === photo.ColorName ? styles.active : ''
              }`}
            onClick={() => handleTabClick(photo.ColorName)}
          >
            {photo.ColorName && (
              <div
                className={styles.colorView}
                style={{ background: findColorValue(photo.ColorName) }}
              >
                {activeTab === photo.ColorName && (
                  <img src='https://ncars.com.ua/catalog/view/theme/ncars/img/check_white.svg' />
                )}
              </div>
            )}
            <div>{photo.ColorName}</div>
          </div>
        ))}
      </div>
      <div className={styles.photoGallery}>
        {photos?.map(
          (photo, photoIndex) =>
            activeTab === photo.ColorName && (
              <Gallery
                onClick={(event) =>
                  handleImageClick(photoIndex, event.target.src)
                }
                key={photo.ColorName}
                photos={photo.Images}
              />
            )
        )}
      </div>
    </div>
  );
}

export default Exterior;
