import styles from './Menu.module.scss';
import MenuMain from './MenuMain/MenuMain';
import MenuNav from './MenuNav/MenuNav';

function Menu({setIsOpenedNav, lang}) {
  const isMobile = window.innerWidth <= 768;
  
  return (
    <div className={styles.container}>
      <MenuNav setIsOpenedNav={setIsOpenedNav} lang={lang} />
      <MenuMain setIsOpenedNav={setIsOpenedNav} lang={lang} />
    </div>
  );
}

export default Menu;
