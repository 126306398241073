import styles from './Table.module.scss';
import { texts } from '../../../../Languages/texts';

function Table({ text, carPackages, lang }) {
  const carTitles = carPackages?.map((car, index) => (
    <div key={car.Id} className={styles.column}>
      <div style={{ backgroundColor: '#F7F7F8' }} className={styles.title}>
        {car.Name}
      </div>
      <div>{car.BaseDescription.BatteryCapacity}</div>
      <div style={{ backgroundColor: '#F7F7F8' }}>
        {car?.BaseDescription.NEDC}
      </div>
      <div>
        {car.BaseDescription.Dispersal !== 0
          ? car.BaseDescription.Dispersal
          : '-'}
      </div>
      <div style={{ backgroundColor: '#F7F7F8' }}>
        {car.BaseDescription.NumberOfSeats}
      </div>
      <div>{car?.BaseDescription?.Power}</div>
      <div style={{ backgroundColor: '#F7F7F8' }}>
        {car.BaseDescription.MaximumSpeed}
      </div>
      <div>{car.BaseDescription.Occasion}</div>
      <div style={{ backgroundColor: '#F7F7F8' }}>
        {car.BaseDescription.ChargingSpeed}
      </div>
    </div>
  ));

  return (
    <div className={styles.container}>
      <div className={styles.tab}>
        <div className={styles.column}>
          <div style={{ backgroundColor: '#F7F7F8' }} className={styles.title}>
            {texts[lang]['techH3']}
          </div>
          <div>{texts[lang]['techItems1']}</div>
          <div style={{ backgroundColor: '#F7F7F8' }}>
            {texts[lang]['techItems2']}
          </div>
          <div>{texts[lang]['techItems3']}</div>
          <div style={{ backgroundColor: '#F7F7F8' }}>
            {texts[lang]['techItems4']}
          </div>
          <div>{texts[lang]['techItems5']}</div>
          <div style={{ backgroundColor: '#F7F7F8' }}>
            {texts[lang]['techItems6']}
          </div>
          <div>{texts[lang]['techItems7']}</div>
          <div style={{ backgroundColor: '#F7F7F8' }}>
            {texts[lang]['techItems8']}
          </div>
        </div>
        {carTitles}
      </div>
      <div>{text}</div>
    </div>
  );
}

export default Table;
